import {Injectable} from '@angular/core';
import * as ExcelJS from 'exceljs';
import {saveAs} from 'file-saver';
import {Entity} from '../classes/entity.class';
import {formatCurrency, formatDate} from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class VkmExcelExportService {
    async createExcelExport(data: any, entitiesMap: Map<number, Entity>) {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Overzicht');

        // Header styling
        const headerStyle = {
            font: {bold: true, color: {argb: 'FFFFFFFF'}},
            alignment: {horizontal: 'center'},
            fill: {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FF000080'}}
        };

        const errorStyle = {
            font: {bold: true, color: {argb: 'FFFFFFFF'}},
            alignment: {horizontal: 'center'},
            fill: {type: 'pattern', pattern: 'solid', fgColor: {argb: 'FFFF0000'}}
        };

        const centerStyle = {
            alignment: {horizontal: 'center'}
        };

        // "Materieel" section
        worksheet.addRow(['Materieel', 'Aanvang', 'Einde', 'Dagen', 'Geleverd', 'Huurdagen', 'Tarief', 'Kosten', 'Kwijt', 'Tarief', 'Kosten']).eachCell((cell) => {
            Object.assign(cell, headerStyle);
        });

        data.objects.forEach((item: any) => {
            const row = worksheet.addRow([
                entitiesMap.get(item.entity_id).name, // Materieel placeholder (you may replace with descriptive name)
                item.date_placed ? formatDate(item.date_placed, 'dd-MM-yyyy', 'nl') : '',
                item.date_removed ? formatDate(item.date_removed, 'dd-MM-yyyy', 'nl') : '',
                item.days, // Dagen placeholder
                item.count_placed,
                item.total_days, // Huurdagen placeholder
                `€ ${parseFloat(item.price_unit).toFixed(2)}`,
                `€ ${parseFloat(item.price).toFixed(2)}`,
                item.count_placed == (item.count_removed + item.count_lost) ? item.count_lost : (item.count_placed - item.count_removed),
                item.count_lost ? formatCurrency(item.price, 'EUR', 'nl') : '',
                item.count_lost ? formatCurrency(item.price_total, 'EUR', 'nl') : '',
            ]);
            [4, 5, 6, 9].forEach(i => {
                row.getCell(i).style = centerStyle;
            });
            if (item.count_placed != (item.count_removed + item.count_lost)) {
                row.getCell(9).style = errorStyle;
            }
        });

        worksheet.addRow([]); // Empty row for spacing

        // "Voorbereiding en uitvoering" section
        worksheet.addRow(['Voorbereiding en uitvoering', 'Aantal', 'Tarief', 'Kosten']).eachCell((cell) => {
            Object.assign(cell, headerStyle);
        });

        data.materials.forEach((item: any) => {
            const row = worksheet.addRow([
                'Man met Bus (dag)',
                item.count_placed,
                `€ ${parseFloat(item.price_unit).toFixed(2)}`,
                `€ ${parseFloat(item.price_unit_total).toFixed(2)}`,
            ]);
            row.getCell(2).style = centerStyle;
        });

        worksheet.addRow([]); // Empty row for spacing

        // "Afrekenstaat" section
        worksheet.addRow(['Afrekenstaat', 'Kosten']).eachCell((cell) => {
            Object.assign(cell, headerStyle);
        });

        data.totalPrices.forEach((item: any) => {
            worksheet.addRow([
                item.settle_group,
                `€ ${parseFloat(item.price).toFixed(2)}`,
            ]);
        });

        const totalStyle = {
            font: {bold: true},
        };
        worksheet.addRow(['Totaal', `€ ${parseFloat(data.totalPrice).toFixed(2)}`]).eachCell((cell) => {
            Object.assign(cell, totalStyle);
        });

        // Column widths for better visibility
        worksheet.columns = [
            {width: 30},
            {width: 15},
            {width: 15},
            {width: 10},
            {width: 10},
            {width: 15},
            {width: 10},
            {width: 15},
            {width: 10},
            {width: 10},
            {width: 10},
        ];

        // Save file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        saveAs(blob, 'Overzicht.xlsx');
    }
}
