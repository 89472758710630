import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlsOf, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {formatDate, formatNumber} from '@angular/common';
import {CodaltComponent} from '../codalt.component';
import {RealisationService} from '../services/realisation.service';
import {Utils} from '../utils.class';
import {Realisation, RealisationHourtype} from '../classes/realisation';
import {User} from '../classes/user.class';
import {combineLatest, concat, of, Subscription} from 'rxjs';
import {Settlement, SettlementHourtype} from '../classes/settlement.class';
import {SettlementsService} from '../services/settlements.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {Hourtype} from '../afas-classes/hourtype';
import {Title} from '@angular/platform-browser';
import {environment} from '../../environments/environment';
import {MinutesPipe} from '../pipes/minutes.pipe';
import {Address, LocationService} from '../services/location.service';
import {Router} from '@angular/router';
import {AfasService} from '../services/afas.service';
import {AanAfwezigheid} from '../afas-classes/aanafwezigheid.class';
import {UserService, UserType} from '../services/user/user.service';
import {ApiResponse} from '../services/api/api.service';

@Component({
    selector: 'app-period-settlement',
    templateUrl: './period-settlement.component.html',
    styleUrls: ['./period-settlement.component.scss']
})
export class PeriodSettlementComponent extends CodaltComponent implements OnInit, OnChanges {

    @Input() print = false;


    weeks: Week[];

    @Input() beginDate: Date;
    @Input() endDate: Date;

    settlements: Settlement[];
    realisations: Realisation[];
    @Input() user: User;
    @Input() daytypeCounts: { type: string; count: number; minutes: number; }[];


    userSubscriptions = new Subscription();

    increaseOvertimeMap = new Map<string, string>([
        ['150', '150V'],
        ['145', '145V'],
        ['135', '135V'],
        ['130', '130V']
    ]);

    fcHourTypeMap = new Map<string, string>([
        ['200', 'overtime200'],
        ['200Z', 'overtime200'],
        ['200V', 'overtime200'],
        ['150', 'overtime150'],
        ['150Z', 'overtime150'],
        ['150V', 'overtime150'],
        ['145', 'overtime145'],
        ['140', 'overtime140'],
        ['135', 'overtime135'],
        ['130', 'overtime130'],
        ['125', 'overtime125'],
        ['100', 'overtime100'],
        ['S100', 'overtime100'],
        ['S100W', 'overtime100'],
        ['100B', 'overtime100'],
        ['SDW', 'overtime100'],
        ['150V', 'bonus50'],
        ['145V', 'bonus45'],
        ['135V', 'bonus35'],
        ['130V', 'bonus30'],
        ['RU', 'traveltime'],
        ['CRW', 'travelcost'],
        ['UITV_MIN2', 'notPaidHours']
    ]);
    contractHourTypeCodesWork = [
        '2 ', 'GU'
    ];
    contractHourTypeCodes = [
        ...this.contractHourTypeCodesWork, 'RV', 'V', 'Z', 'Vorst', 'S', 'SD', 'SDU', 'F', 'AG', 'G', 'KV', 'OU', 'OUO', 'OV', 'SG', 'SU', 'T', 'TO', 'NV', 'B2022', 'A', 'F', 'OB', 'BV'
    ];
    overtimeHourTypeCodes = [
        '200', '200Z', '150', '150Z', '145', '140', '135', '130', '125', '100', '100B'
    ];

    secondLineHourTypeCodes = [
        SettlementHourtype.sleep,
        SettlementHourtype.sleepWeekend,
        SettlementHourtype.paid_leave,
        SettlementHourtype.bank_holiday,
        SettlementHourtype.short_leave,
        SettlementHourtype.special_leave
    ];
    secondLineRealisationHourTypes = [
        RealisationHourtype.sleep,
        RealisationHourtype.paid_leave,
        RealisationHourtype.bank_holiday,
        RealisationHourtype.short_absence,
        RealisationHourtype.special_leave
    ];

    nonTimeHourTypes = ['CRW'];

    supplementCaoHoursText = 'Aanvullen tot 8 uur (CAO)';

    sleepOrLeaveHourCodes = [];

    fcNamePeriodTotalMap: Map<string, number>;

    hourtypes: Hourtype[];

    dayDetail: WeekDay;
    hoverWeekButton?: string;

    caoBouwInfra = false;

    settling = false;

    aanAfwezigheid: AanAfwezigheid[];

    invalidTimezone = false;

    showTravelTo = false;

    constructor(private realisationService: RealisationService,
                private settlementService: SettlementsService,
                private confirmDialogService: ConfirmDialogService,
                private settlementsService: SettlementsService,
                private locationService: LocationService,
                private afasService: AfasService,
                private router: Router,
                private title: Title) {
        super();
        this.title.setTitle('Periodeverrekening' + environment.titleAppend);
        this.contractHourTypeCodes.forEach(cht => {
            this.fcHourTypeMap.set(cht, 'normal');
        });

        if ((new Date(2024, 0, 1).getTimezoneOffset()) !== -60) {
            this.invalidTimezone = true;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.getData();
    }

    ngOnInit(): void {
        this.print = !this.print && !UserService.userHasRights(UserType.SETTLEMENT);
    }

    editHours(date: Date, userId: number) {
        this.router.navigate([
            `/hours/${formatDate(date, 'yyyy-MM-dd', 'nl')}/${userId}`,
            {backTo: window.location.pathname}
        ]);
    }

    private getData() {
        this.userSubscriptions?.unsubscribe();
        this.userSubscriptions = new Subscription();
        this.dayDetail = null;
        this.showTravelTo = false;

        const getData = () => {
            const leaves$ = UserService.userHasRights(UserType.SETTLEMENT) ? this.afasService.getAanAfwezigheid(this.beginDate, this.endDate, this.user.afas_employee_id) : of<ApiResponse>(null);

            this.userSubscriptions.add(combineLatest([
                this.realisationService.getUserSchedule(this.beginDate, this.endDate, this.user.id),
                this.settlementService.getUserSettlements(this.beginDate, this.endDate, this.user.id),
                leaves$
            ]).subscribe(([realisations, settlements, aanafwezigheid]) => {
                this.aanAfwezigheid = aanafwezigheid?.data;
                this.realisations = realisations.data;
                this.settlements = settlements.data;
                this.parseData();
            }));
        };

        if (this.hourtypes) {
            getData();
        } else {
            this.subscriptions.add(this.settlementsService.getHourtypes().subscribe(hourtypes => {
                this.hourtypes = hourtypes.data;
                getData();
            }));
        }

    }

    getLargestSettlementProject(settlements: Settlement[]) {
        const ordered = settlements.filter(s => {
            s.realisation = this.realisations.find(r => r.id === s.realisation_id);
            return !!s.realisation && !!Utils.realisationAfasProjectId(s.realisation);
        }).sort((a, b) => b.minutes - a.minutes);
        if (ordered?.length) {
            return Utils.realisationAfasProjectId(ordered[0].realisation);
        }
        return;
    }

    showDayDetails(event, day: WeekDay) {
        event.preventDefault();
        if (this.print) {
            return;
        }

        this.dayDetail = day;
    }

    createSettlementFromRealisation(realisation: Realisation, minutes: number, type: string, from: Date, to: Date, subStart = 0, subEnd = 0) {
        const settlement = new Settlement();
        settlement.user_id = realisation.user_id;
        settlement.bookdate = realisation.bookdate;
        settlement.realisation_id = realisation.id;
        settlement.minutes = minutes;
        settlement.hourtype_code = type;
        settlement.hourtype = this.hourtypes.find(h => h.code === settlement.hourtype_code);
        from = new Date(from);
        to = new Date(to);
        if (subStart) {
            from.setMinutes(from.getMinutes() + subStart);
        }
        if (subEnd) {
            to.setMinutes(to.getMinutes() - subEnd);
        }

        settlement.description = `${formatDate(from, 'd MMM yyyy HH:mm', 'nl')} - `;
        if (from.getDate() === to.getDate()) {
            settlement.description += `${formatDate(to, 'HH:mm', 'nl')}`;
        } else {
            settlement.description += `${formatDate(to, 'd MMM yyyy HH:mm', 'nl')}`;
        }
        if (realisation.hourtype === RealisationHourtype.sleep) {
            settlement.description = `Slaapdag ${settlement.description}`;
        }
        if ([RealisationHourtype.travel_to, RealisationHourtype.travel_back].includes(realisation.hourtype) && realisation.text_prepend && realisation.text_append) {
            settlement.description += ` van ${realisation.text_prepend} naar ${realisation.text_append}`;
        }
        return settlement;
    }

    settlePeriodHours() {
        this.settling = true;
        const settle = (counter: number) => {
            this.settleWeekHours(this.weeks[counter]).then(() => {
                if ((this.weeks.length - 1) > counter) {
                    settle(counter + 1);
                } else {
                    this.settling = false;
                }
            })
        };
        settle(0);
    }

    removeWeek(week: Week) {
        if (!week.settling) {
            week.settling = true;
            this.confirmDialogService.confirm(
                'Wil je alle nacalculatieregels verwijderen?',
                `We verwijderen alle nacalculatieregels voor ${this.user.name} in week ${week.weeknumber} uit Afas.<br>Doe dit alleen als deze periode nog niet verloond is.<br><b>Deze actie kan niet teruggedraaid worden.</b>`,
                'Regels verwijderen',
                'Annuleren'
            ).then(() => {
                const all$ = [];
                let loadCount = 0;
                week.weekDays[0].settling = true;
                week.weekDays.forEach(weekDay => {
                    all$.push(this.settlementsService.deleteDay(weekDay.date, this.user.id));
                });
                concat(...all$).subscribe(() => {
                    week.weekDays[loadCount].settling = false;
                    loadCount++;
                    if (loadCount === week.weekDays.length) {
                        week.settling = false;
                        this.hoverWeekButton = null;
                        this.getData();
                    } else {
                        week.weekDays[loadCount].settling = true;
                    }
                });
            }, () => {
                week.settling = false;
            });
        }
    }

    settleWeekHours(week: Week) {
        week.settling = true;
        return new Promise((resolve) => {
            if (week.sendToAfas) {
                week.settling = false;
                resolve(null);
                return;
            }

            if (week.weekDays.filter(wd => wd.realisations?.length > 0).length === 0 && this.aanAfwezigheid?.filter(l => week.weekDays.map(wd => Utils.dateString(wd.date)).includes(Utils.dateString(l.startDate)) && (!!l.leaveCode || !!l.typeverzuimcode))?.length === 0) {
                week.settling = false;
                resolve(null);
                return;
            }
            const settle = (counter: number) => {
                this.settleDayHours(week.weekDays[counter]).then(() => {
                    if ((week.weekDays.length - 1) > counter) {
                        settle(counter + 1);
                    } else {
                        if (this.user.group === 'CHAU') {
                            // als chauffeur en uren tekort, pakken van andere dag in de week goedkoopste uurtype
                            const contractMinutesDay = (this.user.contract_hours / (this.user.contract_hours / 8)) * 60;

                            const daysWithShortage = week.weekDays
                                .filter(d => d.settlements
                                        .filter(s => this.contractHourTypeCodes.includes(s.hourtype_code))
                                        .reduce((sum, current) => sum + current.minutes, 0) < contractMinutesDay &&
                                    d.date.getDay() > 0 && d.date.getDay() < 6
                                );

                            const dayShortageCalculation = (dayWithShortage: WeekDay) => {
                                return new Promise((resolve) => {
                                    const totalNormalMinutes = dayWithShortage.settlements
                                        .filter(s => this.contractHourTypeCodes.includes(s.hourtype_code))
                                        .reduce((sum, current) => sum + current.minutes, 0);
                                    let contractMinutesToFew = (contractMinutesDay - totalNormalMinutes);
                                    if (contractMinutesToFew > 0) {
                                        const getShortageDays = () => {
                                            return new Promise<WeekDay[]>((resolve) => {
                                                const daysUsed = [] as WeekDay[];
                                                let loopFinished = false;
                                                week.weekDays.forEach((day, i) => {
                                                    let someChange = false;
                                                    day.settlements.filter(s => this.overtimeHourTypeCodes.includes(s.hourtype_code) && !s.hourtype_code.includes('Z'))
                                                        .sort((a, b) => {
                                                            const aa = `${(a.hourtype_code.replace('Z', ''))}`;
                                                            const bb = `${(b.hourtype_code.replace('Z', ''))}`;
                                                            return aa.localeCompare(bb);
                                                        }).forEach((settlement) => {
                                                        if (contractMinutesToFew > 0) {
                                                            const subtract = Math.min(contractMinutesToFew, settlement.minutes);
                                                            if (subtract > 0) {
                                                                someChange = true;
                                                                settlement.minutes = settlement.minutes - subtract;
                                                                settlement.description = `${settlement.description} - ${(new MinutesPipe()).transform(subtract)} voor ${formatDate(dayWithShortage.date, 'd MMM yyyy', 'nl')}`;
                                                                contractMinutesToFew -= subtract;
                                                                // convert overtime 1xx % to xx % and let alone on the same day
                                                                const overtimeSettlement = new Settlement();
                                                                overtimeSettlement.user_id = this.user.id;
                                                                overtimeSettlement.bookdate = settlement.bookdate;
                                                                overtimeSettlement.minutes = subtract;
                                                                overtimeSettlement.hourtype_code = this.increaseOvertimeMap.get(settlement.hourtype_code) ?? overtimeSettlement.hourtype_code;
                                                                overtimeSettlement.hourtype = this.hourtypes.find(h => h.code === overtimeSettlement.hourtype_code);
                                                                overtimeSettlement.description = settlement.description;
                                                                day.settlements.push(overtimeSettlement);
                                                            }
                                                        }
                                                    });
                                                    if (someChange) {
                                                        daysUsed.push(day);
                                                        day.settling = true;
                                                        this.saveSettlements(day, day.settlements, () => {
                                                            day.settling = false;
                                                            if (i === (week.weekDays.length - 1) || (loopFinished && !week.weekDays.find(d => d.settling))) {
                                                                resolve(daysUsed);
                                                            }
                                                        });
                                                    } else {
                                                        if (i === (week.weekDays.length - 1) && !week.weekDays.find(d => d.settling)) {
                                                            resolve(daysUsed);
                                                        }
                                                    }
                                                });
                                                loopFinished = true;
                                            });
                                        };

                                        getShortageDays().then(daysUsed => {
                                            if (daysUsed.length) {
                                                const settlement = new Settlement();
                                                settlement.user_id = this.user.id;
                                                settlement.bookdate = formatDate(dayWithShortage.date, 'yyyy-MM-dd', 'nl') as any;
                                                settlement.minutes = (contractMinutesDay - totalNormalMinutes) - contractMinutesToFew;
                                                settlement.hourtype_code = 'GU';
                                                settlement.hourtype = this.hourtypes.find(h => h.code === settlement.hourtype_code);
                                                settlement.description = 'Aanvullen tot 8 uur met overuren van ';
                                                daysUsed.forEach((du, i) => {
                                                    settlement.description += ` ${formatDate(du.date, 'EEEE', 'nl')}`;
                                                    if (i < (daysUsed.length - 1)) {
                                                        settlement.description += ',';
                                                    }
                                                });
                                                dayWithShortage.settlements.push(settlement);
                                                dayWithShortage.settling = true;
                                                this.saveSettlements(dayWithShortage, dayWithShortage.settlements, () => {
                                                    dayWithShortage.settling = false;
                                                    resolve(null);
                                                });
                                            } else {
                                                resolve(null);
                                            }
                                        });

                                    }
                                });
                            };

                            const dayShortageCalculationLoop = (indexDay = 0) => {
                                let dws = daysWithShortage[indexDay];
                                if (dws) {
                                    dayShortageCalculation(dws).then(() => {
                                        dayShortageCalculationLoop(indexDay + 1);
                                    }, () => {

                                    });
                                }
                            };
                            dayShortageCalculationLoop();
                        }

                        week.settling = false;
                        resolve(true);
                    }
                });
            };
            settle(0);
        });
    }

    isGapMoreThanTwoHours(realisationArray: Realisation[]): boolean {
        let twoHourGap = false;
        realisationArray.sort((a, b) => Utils.getTimeOrNull(a.begindate) - Utils.getTimeOrNull(b.begindate));
        for (let i = 0; i < realisationArray.length - 1; i++) {
            const endDateInMilliseconds = Utils.getTimeOrNull(realisationArray[i].enddate);
            const nextBeginDateInMilliseconds = Utils.getTimeOrNull(realisationArray[i + 1].begindate);
            const gapInHours = (nextBeginDateInMilliseconds - endDateInMilliseconds) / 1000 / 60 / 60;
            if (gapInHours >= 2) {
                twoHourGap = true;
                break;
            }
        }

        return twoHourGap;
    }

    settleDayHours(day: WeekDay) {
        day.settling = true;
        return new Promise((resolve) => {
            if (day.sendToAfas) {
                day.settling = false;
                resolve(null);
                return;
            }
            const settlements = [] as Settlement[];
            const minDayHours = (8 * 60);

            let totals = [] as timeTypeRealisationSettle[];

            const caoBouwInfra = this.user.group !== 'CHAU';

            const some2hourGap = this.isGapMoreThanTwoHours(day.realisations);

            day.realisations
                .filter(r => [RealisationHourtype.driving_to, RealisationHourtype.driving_back, RealisationHourtype.worktime, RealisationHourtype.travel_back, RealisationHourtype.travel_to].includes(r.hourtype))
                .forEach(realisation => {
                    const beginDate = new Date(realisation.begindate);
                    const endDate = new Date(realisation.enddate);

                    const startEarly = Utils.setTime(new Date(endDate), 0, 0);
                    const endEarly = Utils.setTime(new Date(endDate), 6, 0);
                    const startNight = Utils.setTime(new Date(beginDate), caoBouwInfra ? 20 : 18, 0);
                    const midNight = Utils.setTime(new Date(beginDate), 24, 0);

                    let nightMinutes = 0;
                    let earlyMinutes = 0;

                    // nacht
                    if (beginDate.getTime() < midNight.getTime() && endDate.getTime() > startNight.getTime()) {
                        const calcUntil = endDate.getTime() > midNight.getTime() ? midNight : endDate;
                        const calcFrom = beginDate.getTime() < startNight.getTime() ? startNight : beginDate;
                        const minutes = Utils.minuteDuration(calcUntil, calcFrom);
                        totals.push({
                            from: calcFrom,
                            to: calcUntil,
                            minutes,
                            type: TimeOnDay.Night,
                            realisation
                        });
                        nightMinutes += minutes;
                        ``
                    }
                    // ochtend
                    if (beginDate.getTime() < endEarly.getTime() && endDate.getTime() > startEarly.getTime()) {
                        const calcFrom = beginDate.getTime() < startEarly.getTime() ? startEarly : beginDate;
                        const calcUntil = endDate.getTime() > endEarly.getTime() ? endEarly : endDate;
                        const minutes = Utils.minuteDuration(calcUntil, calcFrom);
                        totals.push({
                            from: calcFrom,
                            to: calcUntil,
                            minutes,
                            type: TimeOnDay.Early,
                            realisation
                        });
                        earlyMinutes += minutes;
                    }
                    // normaal
                    const minutesNormal = Utils.minuteDuration(endDate, beginDate) - (nightMinutes + earlyMinutes);
                    if (minutesNormal) {
                        const from = new Date(beginDate);
                        const to = new Date(endDate);
                        let minutesNormalFirst = 0;
                        if (from.getTime() < startEarly.getTime() && from.getTime() < startNight.getTime()) {
                            const normalFromFirst = new Date(from);
                            const normalToFirst = new Date(Math.min(startNight.getTime(), startEarly.getTime()));
                            const minutes = Utils.minuteDuration(normalToFirst, normalFromFirst);
                            totals.push({
                                from: normalFromFirst,
                                to: normalToFirst,
                                minutes,
                                type: TimeOnDay.Normal,
                                realisation
                            });
                            minutesNormalFirst += minutes;
                        }

                        if (minutesNormalFirst) {
                            from.setMinutes(from.getMinutes() + (nightMinutes + earlyMinutes));
                        } else {
                            from.setMinutes(from.getMinutes() + earlyMinutes);
                            to.setMinutes(to.getMinutes() - nightMinutes);
                        }

                        if ((minutesNormal - minutesNormalFirst) > 0) {
                            const minutes = minutesNormal - minutesNormalFirst;
                            let fromNormalSec = new Date(from);
                            if (minutesNormalFirst) {
                                fromNormalSec = new Date(to);
                                fromNormalSec.setMinutes(fromNormalSec.getMinutes() - minutes);
                            }
                            totals.push({
                                from: fromNormalSec,
                                to,
                                minutes,
                                type: TimeOnDay.Normal,
                                realisation
                            });
                        }
                    }
                });

            const hasContractHours = day.fg.value.contractHours > 0;

            // Afas responds with a startdate of 0:00Z, which is 2:00 NL, so we filter on a time middle of the day
            const filterDayDate = Utils.setTime(Utils.newDate((day.date)), 10, 0).getTime();


            // slaapuren
            day.realisations
                .filter(r => [RealisationHourtype.sleep].includes(r.hourtype))
                .forEach(realisation => {
                    const beginDate = new Date(realisation.begindate);
                    const endDate = new Date(realisation.enddate);
                    const minutes = Utils.minuteDuration(endDate, beginDate);
                    const hourCode = day.date.getDay() > 0 && day.date.getDay() < 6 ? 'SDU' : 'SDW';
                    const settlement = this.createSettlementFromRealisation(realisation, minutes, hourCode, beginDate, endDate);
                    settlements.push(settlement);
                });

            //feestdagen
            day.realisations
                .filter(r => [RealisationHourtype.bank_holiday].includes(r.hourtype))
                .forEach(realisation => {
                    const beginDate = new Date(realisation.begindate);
                    const endDate = new Date(realisation.enddate);
                    const minutes = Utils.minuteDuration(endDate, beginDate);
                    let hourtype = 'F';
                    const settlement = this.createSettlementFromRealisation(realisation, minutes, hourtype, beginDate, endDate);
                    settlements.push(settlement);
                });
            //scholing
            const education = day.realisations.filter(r => [RealisationHourtype.education].includes(r.hourtype));
            const totalEducation = education.map(e => {
                const beginDate = new Date(e.begindate);
                const endDate = new Date(e.enddate);
                return Utils.minuteDuration(endDate, beginDate);
            }).reduce((sum, current) => sum + current, 0);

            education.forEach(realisation => {
                const beginDate = new Date(realisation.begindate);
                const endDate = new Date(realisation.enddate);
                const minutes = Utils.minuteDuration(endDate, beginDate);

                let settledMinutes = settlements.map(s => s.minutes).reduce((sum, current) => sum + current, 0) - realisation.pause;
                const tMinutes = settledMinutes + minutes;

                let aboveContract = 0;
                let normal = minutes;
                let contractHours = day.fg.value.contractHours + realisation.pause;
                if (totalEducation >= contractHours) {
                    settledMinutes = settledMinutes - day.fg.value.pause;
                }

                if (tMinutes > day.fg.value.contractHours || day.fg.value.contractHours === 0) {
                    const minInContractLeft = Math.max(contractHours - settledMinutes, 0);
                    aboveContract = minutes - minInContractLeft;
                    normal = minInContractLeft;
                }
                if (normal) {
                    settlements.push(this.createSettlementFromRealisation(realisation, normal, 'S', beginDate, endDate));
                }
                if (aboveContract) {
                    const hourCode = day.date.getDay() > 0 && day.date.getDay() < 6 ? 'S100' : 'S100W';
                    settlements.push(this.createSettlementFromRealisation(realisation, aboveContract, hourCode, beginDate, endDate));
                }
            });
            let pauseToSettle = day.fg.value.pause;
            if (day.date.getDay() > 0 && day.date.getDay() < 6) {
                let settledMinutes = settlements.map(s => s.minutes).reduce((sum, current) => sum + current, 0);
                if (this.user.overtime) {
                    settledMinutes = 480;
                }
                let sleepMinutes = settlements.filter(s => ['SDU', 'SDW'].includes(s.hourtype_code)).map(s => s.minutes).reduce((sum, current) => sum + current, 0) ?? 0;

                settledMinutes = settledMinutes - pauseToSettle;
                totals.sort((a, b) => {
                    return Utils.getTimeOrNull(a.to) - Utils.getTimeOrNull(b.to);
                }).forEach(total => {
                    const tMinutes = settledMinutes + total.minutes;
                    let minutesFrom12Hours = 0;
                    let aboveContract = 0;
                    let normal = total.minutes;
                    let performer8to10hours = 0;
                    if (tMinutes > day.fg.value.contractHours) {
                        const minInContractLeft = Math.max(day.fg.value.contractHours - settledMinutes, 0);
                        normal = minInContractLeft;
                        aboveContract = total.minutes - minInContractLeft;

                        if (sleepMinutes && aboveContract > 0) {
                            const subFromAbove = Math.min(aboveContract, sleepMinutes);
                            sleepMinutes = subFromAbove;
                            aboveContract = aboveContract - subFromAbove;
                        }
                        if (this.user.function === 'Uitvoerder' && aboveContract > 0 && !some2hourGap) {
                            let minutesFrom8Hours = settlements.filter(s => s.hourtype_code === 'UITV_MIN2').map(s => s.minutes).reduce((sum, current) => sum + current, 0) ?? 0;
                            performer8to10hours = Math.min((120 - minutesFrom8Hours), aboveContract);
                            aboveContract = aboveContract - performer8to10hours;
                        }
                        if (caoBouwInfra) {
                            minutesFrom12Hours = Math.max(tMinutes - 660, 0);
                            aboveContract = aboveContract - minutesFrom12Hours;
                        }
                    }

                    if (performer8to10hours) {
                        settlements.push(this.createSettlementFromRealisation(total.realisation, performer8to10hours, 'UITV_MIN2', total.from, total.to));
                    }
                    if (normal || sleepMinutes) {
                        const settleHours = sleepMinutes > 0 ? sleepMinutes : normal;
                        const hourCodeSuffix = sleepMinutes ? '' : 'V';
                        let hourType = sleepMinutes ? '100B' : 'GU';
                        if (total.type === TimeOnDay.Night) {
                            hourType = caoBouwInfra ? `130${hourCodeSuffix}` : `135${hourCodeSuffix}`;
                            if (this.user.function !== 'Uitvoerder' && caoBouwInfra && day.date.getDay() === 5 && [total.to.getDay(), total.from.getDay()].includes(5)) {
                                hourType = `150${hourCodeSuffix}`;
                            }
                        }
                        if (total.type === TimeOnDay.Early) {
                            hourType = caoBouwInfra ? `130${hourCodeSuffix}` : `145${hourCodeSuffix}`;
                            if (day.date.getDay() === 5 && new Date(total.to).getDay() === 6) {
                                hourType = `150${hourCodeSuffix}`;
                            }
                        }
                        const settlement = this.createSettlementFromRealisation(total.realisation, settleHours, hourType, total.from, total.to)
                        settlements.push(settlement);
                        if (hourType !== 'GU' && !sleepMinutes) {
                            const settl = this.createSettlementFromRealisation(total.realisation, settleHours, 'GU', total.from, total.to);
                            settl.bonus = settlement; // even bij pause eruit filteren hierop
                            settlement.normal = settl;
                            settlements.push(settl);
                        }
                    }
                    if (settlements.find(s => s.hourtype_code === 'F')) {
                        settlements.push(this.createSettlementFromRealisation(total.realisation, aboveContract, '200', total.from, total.to));
                    } else if (aboveContract) {
                        //6:00 - 8:00 check bij bouwInfra, dan 130 bij TimeOnDay.Normal
                        let totalMinutesHigh = 0;
                        if (total.type === TimeOnDay.Normal && caoBouwInfra) {
                            const calcFrom = new Date(total.from);
                            calcFrom.setMinutes(calcFrom.getMinutes() + (normal ?? 0));
                            const highOvertimeStart = Utils.setTime(new Date(total.from), 6, 0);
                            const highOVertimeEnd = Utils.setTime(new Date(total.from), 8, 0);

                            if (calcFrom.getTime() < highOVertimeEnd.getTime() && total.to.getTime() > highOvertimeStart.getTime()) {
                                const calcTo = total.to.getTime() >= highOVertimeEnd.getTime() ? highOVertimeEnd : total.to;
                                totalMinutesHigh = Utils.minuteDuration(calcTo, calcFrom);
                            }
                        }
                        let hourType = caoBouwInfra ? '125' : '130';
                        if (this.user.function === 'Uitvoerder') {
                            hourType = '100B';
                        }
                        if (total.type === TimeOnDay.Night) {
                            hourType = caoBouwInfra ? '130' : '135';

                            // als vrijdagnacht dan 150%, ook voor uitvoerders
                            if (caoBouwInfra && total.from.getDay() === 5) {
                                hourType = '150';
                            }
                        }
                        if (total.type === TimeOnDay.Early) {
                            hourType = caoBouwInfra ? new Date(total.to).getDay() === 1 ? '200' : '130' : '145';
                            //vrijdag op zaterdag
                            if (day.date.getDay() === 5 && new Date(total.to).getDay() === 6) {
                                hourType = '150';
                            }
                        }
                        if (totalMinutesHigh) {
                            let hourTypeHigh = new Date(total.to).getDay() === 1 ? '200' : '130';
                            settlements.push(this.createSettlementFromRealisation(total.realisation, totalMinutesHigh, hourTypeHigh, total.from, total.to));
                        }
                        if (aboveContract - totalMinutesHigh > 0) {
                            settlements.push(this.createSettlementFromRealisation(total.realisation, aboveContract - totalMinutesHigh, hourType, total.from, total.to));
                        }
                    }
                    if (minutesFrom12Hours) {
                        let hourType = '150';
                        // voor uitvoerder alleen vrijdag op zaterdag 150
                        if (this.user.function === 'Uitvoerder'
                            && !(total.type === TimeOnDay.Night && day.date.getDay() === 5) // vrijdag nacht
                            && !(total.type === TimeOnDay.Early && day.date.getDay() === 5 && new Date(total.to).getDay() === 6)
                        ) {
                            hourType = total.type === TimeOnDay.Normal ? '100B' : '130';
                        }
                        if (settlements.find(s => s.hourtype_code === 'F')) {
                            hourType = '200';
                        }
                        settlements.push(this.createSettlementFromRealisation(total.realisation, minutesFrom12Hours, hourType, total.from, total.to));
                    }
                    settledMinutes += normal + aboveContract;
                });
            } else {
                totals.forEach(total => {
                    let hourType = total.from.getDay() === 0 ? '200Z' : '150Z';
                    hourType = day.date.getDay() === 0 ? '200Z' : hourType;
                    settlements.push(this.createSettlementFromRealisation(total.realisation, total.minutes, hourType, total.from, total.to));
                });
            }


            const oneOnOneTypes = [
                {
                    realisation_hourtype: RealisationHourtype.frost,
                    settlement_hourtype: SettlementHourtype.frost,
                    name: 'Vorst'
                },
                {
                    realisation_hourtype: RealisationHourtype.additional_parental,
                    settlement_hourtype: SettlementHourtype.additional_parental,
                    name: 'Aanv. geb verl. + ouderschapsv'
                },
                {
                    realisation_hourtype: RealisationHourtype.special_leave,
                    settlement_hourtype: SettlementHourtype.special_leave,
                    name: 'Bijzonder verlof'
                }
            ];
            oneOnOneTypes.forEach(oneOnOneType => {
                day.realisations
                    .filter(r => [oneOnOneType.realisation_hourtype].includes(r.hourtype))
                    .forEach(realisation => {
                        const beginDate = new Date(realisation.begindate);
                        const endDate = new Date(realisation.enddate);
                        const minutes = Utils.minuteDuration(endDate, beginDate);
                        const settlement = this.createSettlementFromRealisation(realisation, minutes, oneOnOneType.settlement_hourtype, beginDate, endDate);
                        settlements.push(settlement);
                    });
            });


            const nonPauseHourtypes = [
                'SDU',
                'SD',
                'SDW'
            ];

            // pause verrekenen
            settlements
                .filter(s => !s.bonus && nonPauseHourtypes.indexOf(s.hourtype_code) === -1 && !s.skip_pause)
                .sort((a, b) => {
                    let aa = a.hourtype_code.indexOf('V') !== -1 ? `${(+a.hourtype_code.replace('V', '')) - 100}` : a.hourtype_code;
                    let bb = b.hourtype_code.indexOf('V') !== -1 ? `${(+b.hourtype_code.replace('V', '')) - 100}` : b.hourtype_code;
                    aa = aa.indexOf('GU') !== -1 ? '1' : aa;
                    bb = bb.indexOf('GU') !== -1 ? '1' : bb;
                    aa = aa.indexOf('S') !== -1 ? '1' : aa;
                    bb = bb.indexOf('S') !== -1 ? '1' : bb;
                    return aa.padStart(3, '0').localeCompare(bb.padStart(3, '0'));
                })
                .forEach((settlement) => {
                    if (pauseToSettle > 0) {
                        const subtract = Math.min(pauseToSettle, settlement.minutes);
                        settlement.minutes = settlement.minutes - subtract;
                        settlement.description = `${settlement.description} - ${(new MinutesPipe()).transform(subtract)} pauze`;
                        pauseToSettle -= subtract;
                        if (settlement.normal) {
                            settlement.normal.minutes = settlement.minutes;
                            settlement.normal.description = `${settlement.normal.description} - ${(new MinutesPipe()).transform(subtract)} pauze`;
                        }
                    }
                    settlement.bonus = null;
                    settlement.normal = null;
                });

            //ziek
            day.realisations
                .filter(r => [RealisationHourtype.illness].includes(r.hourtype))
                .forEach(realisation => {
                    const beginDate = new Date(realisation.begindate);
                    const endDate = new Date(realisation.enddate);
                    const minutes = Utils.minuteDuration(endDate, beginDate);
                    const settlement = this.createSettlementFromRealisation(realisation, minutes, 'Z', beginDate, endDate);
                    settlements.push(settlement);
                });

            //vrij
            day.realisations
                .filter(r => [RealisationHourtype.day_off, RealisationHourtype.paid_leave].includes(r.hourtype))
                .forEach(realisation => {
                    const beginDate = new Date(realisation.begindate);
                    const endDate = new Date(realisation.enddate);
                    const minutes = Utils.minuteDuration(endDate, beginDate);
                    let hourtype = 'V';
                    const settlement = this.createSettlementFromRealisation(realisation, minutes, hourtype, beginDate, endDate);
                    settlements.push(settlement);
                });
            day.realisations
                .filter(r => [RealisationHourtype.short_absence].includes(r.hourtype))
                .forEach(realisation => {
                    const beginDate = new Date(realisation.begindate);
                    const endDate = new Date(realisation.enddate);
                    const minutes = Utils.minuteDuration(endDate, beginDate);
                    let hourtype = 'KV';
                    const settlement = this.createSettlementFromRealisation(realisation, minutes, hourtype, beginDate, endDate);
                    settlements.push(settlement);
                });
            day.realisations
                .filter(r => [RealisationHourtype.time_for_time].includes(r.hourtype))
                .forEach(realisation => {
                    const beginDate = new Date(realisation.begindate);
                    const endDate = new Date(realisation.enddate);
                    const minutes = Utils.minuteDuration(endDate, beginDate);
                    let hourtype = 'OB';
                    const settlement = this.createSettlementFromRealisation(realisation, minutes, hourtype, beginDate, endDate);
                    settlements.push(settlement);
                });

            // Vrij
            this.aanAfwezigheid?.filter(l => (Utils.dateString(l.startDate) === Utils.dateString(day.date)) && (!!l.leaveCode || !!l.typeverzuimcode))
                .forEach(aanAfw => {
                    const verlofSettlements = settlements.filter(s => this.hourtypes.filter(h => h.type === 'Verlof').map(h => h.code).includes(s.hourtype_code));
                    if (verlofSettlements.map(s => s.minutes).reduce((sum, current) => sum + current, 0) < 480) {
                        const settlement = new Settlement();
                        settlement.user_id = this.user.id;
                        settlement.bookdate = formatDate(day.date, 'yyyy-MM-dd', 'nl') as any;
                        settlement.minutes = aanAfw.hours;
                        settlement.hourtype_code = aanAfw.leaveCode ?? aanAfw.typeverzuimcode;
                        settlement.skip_pause = !!aanAfw.typeverzuimcode;
                        settlement.afas_leave = true;
                        if (settlement.hourtype_code.substring(0, 4) === 'W202' ||
                            settlement.hourtype_code.substring(0, 4) === 'R202' ||
                            settlement.hourtype_code === 'ERVD' ||
                            settlement.hourtype_code === 'TSF') {
                            settlement.hourtype_code = 'V';
                        }
                        if (['B', 'O'].includes(settlement.hourtype_code)) { // Bedrijfsongeval
                            settlement.hourtype_code = 'Z';   // Ziek
                        }

                        settlement.hourtype = this.hourtypes.find(h => h.code === settlement.hourtype_code);
                        settlement.description = `${aanAfw.omschrijving}`;
                        if (settlement.minutes > 0) {
                            settlements.push(settlement);
                            if (aanAfw.leaveCode) {
                                this.sleepOrLeaveHourCodes.push(aanAfw.leaveCode);
                                this.fcHourTypeMap.set(aanAfw.leaveCode, 'normal');
                            }
                        }
                    }
                });

            // als bouw CAO, aanvullen tot 8 uur

            const userEndDate = new Date(this.user.enddate);
            if (userEndDate.getHours() <= 2) {
                userEndDate.setHours(userEndDate.getHours() - 3);
            }
            const userNotOutOFService = !this.user.enddate || new Date(userEndDate) >= day.date;
            if (userNotOutOFService && !this.user.overtime && caoBouwInfra && day.date.getDay() > 0 && day.date.getDay() < 6 && !(this.user.id === 316 && day.date.getDay() === 5)) {
                const totalMinutesDay = settlements.filter(s => [...this.sleepOrLeaveHourCodes, 'GU', 'V', 'Z', 'Vorst', 'S', 'SP', 'G', 'RV', 'SDU', 'F', 'KV', 'OB', 'BV', 'OSV'].includes(s.hourtype_code)).map(s => s.minutes).reduce((sum, current) => sum + current, 0);
                if (totalMinutesDay < minDayHours) {

                    const afasProjectId = this.getLargestSettlementProject(settlements);

                    const settlement = new Settlement();
                    settlement.user_id = this.user.id;
                    settlement.bookdate = formatDate(day.date, 'yyyy-MM-dd', 'nl') as any;
                    settlement.minutes = minDayHours - totalMinutesDay;
                    settlement.hourtype_code = 'GU';
                    settlement.hourtype = this.hourtypes.find(h => h.code === settlement.hourtype_code);
                    settlement.description = this.supplementCaoHoursText;
                    settlement.afas_project_id = afasProjectId;
                    settlements.push(settlement);

                    const lastSettlement = settlements.map(s => {
                        s.realisation = this.realisations.find(r => r.id === s.realisation_id)
                        return s;
                    })
                        .filter(s => !!s.realisation && s.hourtype_code !== 'GU')
                        .filter(s => [RealisationHourtype.worktime, RealisationHourtype.driving_back, RealisationHourtype.driving_to].includes(s.realisation.hourtype))
                        .sort((a, b) => Utils.getTimeOrNull(b.realisation.enddate) - Utils.getTimeOrNull(a.realisation.enddate))[0];

                    if (lastSettlement) {
                        const beginDate = new Date(lastSettlement.realisation.begindate);
                        const endDate = new Date(lastSettlement.realisation.enddate);
                        const startEarly = Utils.setTime(new Date(endDate), 0, 0);
                        const endEarly = Utils.setTime(new Date(endDate), 6, 0);
                        if (endDate.getTime() < endEarly.getTime() && endDate.getTime() > startEarly.getTime()) {
                            const settlement = new Settlement();
                            settlement.user_id = this.user.id;
                            settlement.bookdate = formatDate(day.date, 'yyyy-MM-dd', 'nl') as any;
                            settlement.minutes = minDayHours - totalMinutesDay;
                            settlement.hourtype_code = lastSettlement.hourtype_code;
                            settlement.hourtype = this.hourtypes.find(h => h.code === settlement.hourtype_code);
                            settlement.description = this.supplementCaoHoursText;
                            settlements.push(settlement);
                        }
                    }
                }
            }

            // Verblijfskostenvergoeding
            if (!caoBouwInfra) {
                const workSettlements = settlements.filter(s => [...this.contractHourTypeCodesWork, ...this.overtimeHourTypeCodes].includes(s.hourtype_code));
                const totalMinutesDay = workSettlements.map(s => s.minutes).reduce((sum, current) => sum + current, 0) + day.fg.value.pause;
                if (totalMinutesDay > 240) {

                    const firstRealisation = day.realisations
                        .filter(r => [RealisationHourtype.worktime, RealisationHourtype.driving_back, RealisationHourtype.driving_to].includes(r.hourtype))
                        .sort((a, b) => Utils.getTimeOrNull(a.enddate) - Utils.getTimeOrNull(b.enddate))[0];

                    let totalMinutesVBK01 = 0;
                    let totalMinutesVBK05 = 0;
                    let totalMinutesVBK10 = 0;

                    const realisations = day.realisations.filter(r => [RealisationHourtype.worktime, RealisationHourtype.driving_back, RealisationHourtype.driving_to].includes(r.hourtype));
                    realisations.forEach(realisation => {
                        const beginDate = new Date(realisation.begindate);
                        const endDate = new Date(realisation.enddate);
                        let minutesVBK05 = 0;
                        if (new Date(firstRealisation.begindate).getHours() < 14) {
                            const datesToUse = [endDate];
                            if (beginDate.getDate() !== endDate.getDate()) {
                                datesToUse.push(beginDate);
                            }
                            datesToUse.forEach(dateToUse => {
                                const startVK05 = Utils.setTime(new Date(dateToUse), 18, 0);
                                const endVK05 = Utils.setTime(new Date(dateToUse), 24, 0);
                                if (beginDate.getTime() < endVK05.getTime() && endDate.getTime() > startVK05.getTime()) {
                                    const calcFrom = beginDate.getTime() < startVK05.getTime() ? startVK05 : beginDate;
                                    const calcUntil = endDate.getTime() > endVK05.getTime() ? endVK05 : endDate;
                                    minutesVBK05 = Utils.minuteDuration(calcUntil, calcFrom);
                                }
                                totalMinutesVBK05 += minutesVBK05;
                            });

                        }

                        totalMinutesVBK01 += Utils.minuteDuration(endDate, beginDate) - minutesVBK05;

                    });
                    if (new Date(firstRealisation.begindate).getHours() >= 14 && totalMinutesDay >= 720) {
                        totalMinutesVBK10 = 60;
                    }

                    if (totalMinutesVBK01) {
                        settlements.push(this.accommodationCostsSettlement(day, totalMinutesVBK01, 'VBK01'));
                    }
                    if (totalMinutesVBK05) {
                        settlements.push(this.accommodationCostsSettlement(day, totalMinutesVBK05, 'VBK05'));
                    }
                    if (totalMinutesVBK10) {
                        settlements.push(this.accommodationCostsSettlement(day, totalMinutesVBK10, 'VBK10'));
                    }

                }
            }

            const travelCostAndSave = () => {

                // reiskosten wwv Chauffeurs
                if (!this.caoBouwInfra) {
                    this.getRealisationTravelData(day, true).subscribe(([travelTo, travelFrom, data]) => {
                        if (travelTo?.data) {
                            const kms = Math.min(Math.ceil(((travelTo.data?.kms ?? 0) + (travelFrom.data?.kms ?? 0)) * 100) / 100, this.user.maxkm);
                            if (kms > 0) {
                                settlements.push(this.travelcostSettlement(day, kms, data.origin, data.workBegin, data.workEnd, data.destination));
                            }
                        }
                        this.saveSettlements(day, settlements, () => {
                            day.settling = false;
                            resolve(true);
                        });
                    });
                } else {
                    this.saveSettlements(day, settlements, () => {
                        day.settling = false;
                        resolve(true);
                    });
                }
            };

            // reistijd
            if (this.user.traveltime_workday === 'Staffel' && day.date.getDay() > 0 && day.date.getDay() < 6 && day.fg.value.hoursToReward > 0) {
                this.getRealisationTravelData(day).subscribe(([travelTo, travelFrom, data]) => {
                    if (travelTo) {
                        const highestResult = travelTo.data?.kms > travelFrom.data?.kms ? travelTo.data : travelFrom.data;
                        if (highestResult?.kms > 0) {
                            const minutes = this.calculateTravelTimeFromStaffelKms(highestResult.kms);
                            if (minutes) {
                                settlements.push(this.travelSettlement(day, minutes, highestResult.from, highestResult.to, highestResult.kms));
                            }
                        } else {
                            let addresses = '';
                            if (!travelTo.data) {
                                addresses += origin + '<br>' + data.workBegin + '<br>';
                            }
                            if (!travelFrom.data) {
                                addresses += data.workEnd + '<br>' + data.destination + '<br>';
                            }
                            let reference = '';
                            if (data.realisationStart.user_planning) {
                                reference = 'Personeelsplanning of ingepland personeel: #' + data.realisationStart.user_planning.id;
                            } else if (data.realisationStart.planning_has) {
                                reference = 'Transportplanning: #' + data.realisationStart.planning_has.id;
                            } else if (data.realisationStart.planning?.location) {
                                reference = 'Asfaltplanning: #' + data.realisationStart.planning.id;
                            } else {
                                reference = 'Project (via personeelsplanning): #' + data.realisationStart.planning?.afas_project_id;
                            }
                            this.confirmDialogService.confirm('Kon geen kilometers berekenen',
                                'Er lijkt iets mis te zijn met een van de volgende adressen:<br> ' + addresses +
                                `<br><br>Datum: ${formatDate(data.realisationStart.begindate, 'yyyy-MM-dd HH:mm', 'nl')}` +
                                `<br>Gebruiker: ${this.user.name} #${this.user.id}<br>` + reference,
                                'Sluiten', null).then(() => {
                            });
                            console.error('No route for', {
                                realisationStart: data.realisationStart,
                                realisationEnd: data.realisationEnd,
                                origin: data.origin,
                                workBegin: data.workBegin,
                                workEnd: data.workEnd,
                                destination: data.destination
                            });
                        }

                    }
                    travelCostAndSave();
                });
            } else {
                travelCostAndSave();
            }


        });
    }

    private calculateTravelTimeFromStaffelKms(kms: number) {
        const oneHourMinutes = 60;
        if (this.user.function?.toLowerCase()?.indexOf('asfalt') !== -1 || ['Balkman', 'Machinist wals'].includes(this.user.function)) {
            if ((kms >= 30 && kms < 51) || kms >= 106) {
                const refSpeed = 60; // op basis van 60km/h
                return oneHourMinutes * (kms / refSpeed);
            }
            if (kms >= 51 && kms < 60) {
                return oneHourMinutes;
            }
            if (kms >= 60 && kms < 71) {
                return oneHourMinutes * 1.2;
            }
            if (kms >= 71 && kms < 82) {
                return oneHourMinutes * 1.4;
            }
            if (kms >= 82 && kms < 93) {
                return oneHourMinutes * 1.5;
            }
            if (kms >= 93 && kms < 106) {
                return oneHourMinutes * 1.8;
            }
        } else {
            if (kms >= 120) {
                return oneHourMinutes * 2;
            }
            if (kms >= 70) {
                return oneHourMinutes;
            }
        }
        return 0;
    }

    private saveSettlements(day: WeekDay, settlements: Settlement[], savedFunc?: () => any) {
        day.settlements = settlements;
        this.settlementService.saveMulti(settlements, formatDate(day.date, 'yyyy-MM-dd', 'nl') as any, this.user.id).subscribe(result => {
            day.settlements = result.data;
            this.fcHourTypeMap.forEach(fcName => {
                day.fg.controls[fcName].reset();
                day.fgSecond.controls[fcName].reset();
            });
            day.fg.controls.supplemented.reset();
            this.settlementsToForm(day.settlements, day);
            if (savedFunc) {
                savedFunc();
            }
        });
    }

    private travelcostSettlement(day: WeekDay, kms, from, to, backFrom, backTo) {
        const settlement = new Settlement();
        settlement.user_id = this.user.id;
        settlement.bookdate = formatDate(day.date, 'yyyy-MM-dd', 'nl') as any;
        settlement.minutes = kms;
        settlement.amount = kms;
        settlement.hourtype_code = 'CRW';
        settlement.hourtype = this.hourtypes.find(h => h.code === settlement.hourtype_code);
        settlement.description = `Reiskosten van ${from} naar ${to} en van ${backFrom} naar ${backTo} (${formatNumber(kms, 'nl')} km)`;
        return settlement;
    }

    private travelSettlement(day: WeekDay, minutes, from, to, kms) {
        const settlement = new Settlement();
        settlement.afas_project_id = this.getLargestSettlementProject(day.settlements);
        settlement.user_id = this.user.id;
        settlement.bookdate = formatDate(day.date, 'yyyy-MM-dd', 'nl') as any;
        settlement.minutes = minutes;
        settlement.hourtype_code = 'RU';
        settlement.hourtype = this.hourtypes.find(h => h.code === settlement.hourtype_code);
        settlement.description = `Reisuren van ${from} naar ${to} (${Math.ceil(kms)} km)`;
        return settlement;
    }

    private accommodationCostsSettlement(day: WeekDay, minutes, hourtypeCode) {
        const settlement = new Settlement();
        settlement.afas_project_id = this.getLargestSettlementProject(day.settlements);
        settlement.user_id = this.user.id;
        settlement.bookdate = formatDate(day.date, 'yyyy-MM-dd', 'nl') as any;
        settlement.minutes = minutes;
        settlement.hourtype_code = hourtypeCode;
        settlement.hourtype = this.hourtypes.find(h => h.code === settlement.hourtype_code);
        settlement.description = `Verblijfskostenvergoeding`;
        return settlement;
    }

    private settlementsToForm(settlements: Settlement[], day: WeekDay) {
        settlements.forEach(settlement => {
            settlement.hourtype = this.hourtypes.find(h => h.code === settlement.hourtype_code);
            const fcName = this.fcHourTypeMap.get(settlement.hourtype_code);
            let fg = day.fg;
            if (this.secondLineHourTypeCodes.includes(settlement.hourtype_code as SettlementHourtype)) {
                fg = day.fgSecond;
            }
            if (fcName) {
                const fc = fg.get(fcName) as FormControl<number>;
                let currentValue = fc.value ?? 0;
                currentValue += (this.nonTimeHourTypes.includes(settlement.hourtype_code) ? +settlement.amount : settlement.minutes);
                fc.setValue(currentValue);
            }
            if (settlement.description === this.supplementCaoHoursText && settlement.hourtype_code === 'GU') {
                const fc = fg.controls.supplemented;
                let currentValue = fc.value ?? 0;
                currentValue += (this.nonTimeHourTypes.includes(settlement.hourtype_code) ? +settlement.amount : settlement.minutes);
                fc.setValue(currentValue);
            }
            if (settlement.hourtype_code === 'RU') {
                let userAddress = settlement.description.replace(this.locationService.formatAddress(this.user as Address), '');
                userAddress = userAddress.replace(this.locationService.formatAddress(this.user as Address).replace(',', ''), '');
                day.travelTo = userAddress.replace('Reisuren van', '').replace(' naar ', '');
                this.showTravelTo = true;
            }
        });
        if (this.user.overtime) {
            if (day.date.getDay() > 0 && day.date.getDay() < 6) {
                day.fg.controls.normal.setValue(480 - day.fgSecond.value.normal);
                day.fg.controls.remaining.setValue(day.fg.value.hoursToReward);
            }
        } else {
            const remaining = day.fg.value.hoursToReward ? Math.max(day.fg.value.hoursToReward, day.fg.value.contractHours) - day.fg.value.contractHours : 0;
            day.fg.controls.remaining.setValue(remaining - day.fg.value.notPaidHours);
        }
    }

    private parseData() {
        this.weeks = [];
        this.fcNamePeriodTotalMap = new Map<string, number>();
        this.daytypeCounts.forEach(dtc => {
            dtc.count = 0;
            dtc.minutes = 0;
        });
        let interval = 1;
        let week = new Week();
        const bookdate = new Date(this.beginDate);
        const caoBouwInfra = this.user.group !== 'CHAU';
        this.caoBouwInfra = caoBouwInfra;
        let contractHoursLeft = 0;

        function genFormGroup(dayType: string, beginDate, endDate, interruptions: number, totalMinutes: number, pause: number, hoursToReward: number, alreadyPaid: number) {
            const fg = new FormGroup<ControlsOf<ControlsOf<FgWeekDay>>>(
                {
                    dayType: new FormControl(dayType),
                    begindate: new FormControl(beginDate ? formatDate(beginDate, 'H:mm', 'nl') : null),
                    enddate: new FormControl(endDate ? formatDate(endDate, 'H:mm', 'nl') : null),
                    interruptions: new FormControl(interruptions === 0 ? null : interruptions),
                    hours: new FormControl(totalMinutes),
                    pause: new FormControl(pause),
                    hoursToReward: new FormControl(hoursToReward),
                    notPaidHours: new FormControl(),
                    contractHours: new FormControl(alreadyPaid),
                    remaining: new FormControl(),
                    supplemented: new FormControl(),

                    traveltime: new FormControl(),
                    travelcost: new FormControl(),

                    normal: new FormControl(),
                    bonus30: new FormControl(),
                    bonus35: new FormControl(),
                    bonus45: new FormControl(),
                    bonus50: new FormControl(),

                    overtime100: new FormControl(),
                    overtime125: new FormControl(),
                    overtime130: new FormControl(),
                    overtime135: new FormControl(),
                    overtime140: new FormControl(),
                    overtime145: new FormControl(),
                    overtime150: new FormControl(),
                    overtime200: new FormControl()
                }
            );
            return fg;
        }

        while (bookdate.getTime() < this.endDate.getTime()) {

            if (interval === 4) {
                week.weeknumber = formatDate(bookdate, 'w', 'nl');
            }
            const weekDay = new WeekDay();
            const realisationsDay = this.realisations.filter(r => formatDate(r.bookdate, 'yyyy-MM-dd', 'nl') === formatDate(bookdate, 'yyyy-MM-dd', 'nl'));
            const settlementsDay = this.settlements.filter(r => formatDate(r.bookdate, 'yyyy-MM-dd', 'nl') === formatDate(bookdate, 'yyyy-MM-dd', 'nl'));

            const zzpLeave = realisationsDay.find(r => r.hourtype === RealisationHourtype.zzp_leave);
            if (zzpLeave) {
                this.confirmDialogService.confirm('ZZP verlof geregistreerd',
                    `Op ${formatDate(zzpLeave.begindate, 'EEE d MMM yyyy', 'nl')} is ZZP verlof geregistreerd voor een normale medewerker. Dit is niet toegestaan. Periode verrekenen is niet mogelijk.`,
                    'Sluiten', null).then(() => {

                });
                return;
            }

            const firstOrSecondRow = [{
                realisations: realisationsDay.filter(r => !this.secondLineRealisationHourTypes.includes(r.hourtype)),
                settlements: settlementsDay.filter(s => !this.secondLineHourTypeCodes.includes(s.hourtype_code as SettlementHourtype))
            }, {
                realisations: realisationsDay.filter(r => this.secondLineRealisationHourTypes.includes(r.hourtype)),
                settlements: settlementsDay.filter(s => this.secondLineHourTypeCodes.includes(s.hourtype_code as SettlementHourtype))
            }];

            firstOrSecondRow.forEach((loop, index) => {
                const realisations = loop.realisations;
                const settlements = loop.settlements;

                let alreadyPaid = (this.user.contract_hours / (this.user.contract_hours / 8)) * 60;
                if (bookdate.getDay() === 6 || bookdate.getDay() === 0) {
                    alreadyPaid = 0;
                }
                let totalMinutes = 0;
                let pause = 0;
                let beginDate = null;
                let endDate = null;

                let dayType = '';

                realisations.forEach(realisation => {
                    const leaveHourTypes = [RealisationHourtype.paid_leave, RealisationHourtype.time_for_time, RealisationHourtype.short_absence, RealisationHourtype.illness, RealisationHourtype.day_off, RealisationHourtype.bank_holiday];
                    if (!this.user.overtime || !leaveHourTypes.includes(realisation.hourtype)) {
                        totalMinutes += Utils.minuteDuration(realisation.enddate, realisation.begindate);
                    }
                    pause += realisation.pause;
                    beginDate = beginDate ? new Date(Math.min(Utils.getTimeOrNull(realisation.begindate), Utils.getTimeOrNull(beginDate))) : new Date(realisation.begindate);
                    endDate = new Date(Math.max(Utils.getTimeOrNull(realisation.enddate), Utils.getTimeOrNull(endDate)));
                    if (!endDate || endDate.getTime() < new Date(realisation.enddate).getTime()) {
                        endDate = new Date(realisation.enddate);
                    }
                    if (realisation.hourtype === RealisationHourtype.sleep) {
                        dayType = ' ';
                    }
                });

                const hoursToReward = totalMinutes - pause;

                const dayTypeMap = {
                    [SettlementHourtype.workingHours]: 'Werkdag',
                    ['empty']: 'Weekend',
                    [SettlementHourtype.illness]: 'Ziek',
                    [SettlementHourtype.frost]: 'Vorst',
                    [SettlementHourtype.education]: 'Scholing',
                    [SettlementHourtype.paid_leave]: 'Verlof',
                    [SettlementHourtype.short_leave]: 'Onbetaald verlof',
                    [SettlementHourtype.not_paid]: 'Onbetaalde uren',
                    [SettlementHourtype.bank_holiday]: 'Feestdag',
                    [SettlementHourtype.additional_parental]: 'Aanv. geb verl. + ouderschapsv',
                    [SettlementHourtype.special_leave]: 'Bijzonder verlof'
                };

                this.overtimeHourTypeCodes.forEach(ht => {
                    dayTypeMap[ht] = 'Werkdag';
                });

                dayTypeMap[SettlementHourtype.sleep] = 'Slaapdag';
                dayTypeMap[SettlementHourtype.sleepWeekend] = 'Slaapdag';


                Object.keys(dayTypeMap).forEach(hourType => {
                    dayType = settlements.find(r => r.hourtype_code === hourType) ? dayTypeMap[hourType] : dayType;
                    if (!this.daytypeCounts.find(dtc => dtc.type === dayTypeMap[hourType])) {
                        this.daytypeCounts.push({type: dayTypeMap[hourType], count: 0, minutes: 0});
                    }
                });
                if (dayType === dayTypeMap[SettlementHourtype.workingHours] && (bookdate.getDay() === 0 || bookdate.getDay() === 6)) {
                    dayType = 'Weekend';
                }

                if (dayType?.length > 1) {
                    this.daytypeCounts.find(x => x.type === dayType).count++;

                    Object.keys(dayTypeMap).forEach(hourType => {
                        let minutesOfHourType = 0;
                        settlements.filter(r => r.hourtype_code === hourType).forEach(settlement => {
                            minutesOfHourType += settlement.minutes;
                        });
                        let addToType = dayTypeMap[hourType];
                        if (hourType === RealisationHourtype.worktime && dayType === 'Weekend') {
                            addToType = dayType;
                        }
                        this.daytypeCounts.find(x => x.type === addToType).minutes += minutesOfHourType;
                    });
                } else {
                    if (settlements.find(s => s.hourtype_code === 'F')) {
                        dayType = 'Feestdag';
                    }
                    if (settlements.find(s => ['B2022', 'V', 'KV'].includes(s.hourtype_code))) {
                        dayType = 'Verlof';
                    }
                    if (settlements.find(s => s.hourtype_code === 'Z')) {
                        dayType = 'Ziek';
                    }
                }

                const interruptions = Utils.minuteDuration(endDate, beginDate) - totalMinutes;
                const fg = genFormGroup(dayType, beginDate, endDate, interruptions, totalMinutes, pause, hoursToReward, alreadyPaid);
                fg.disable();
                const fgSecond = genFormGroup(dayType, beginDate, endDate, interruptions, totalMinutes, pause, hoursToReward, alreadyPaid);
                fgSecond.disable();
                if (index > 0) {
                    weekDay.fgSecond = fgSecond;
                } else {
                    weekDay.fg = fg;
                }
            });

            weekDay.realisations = realisationsDay;
            weekDay.settlements = [];

            weekDay.date = new Date(bookdate);
            this.settlementsToForm(settlementsDay, weekDay);
            weekDay.settlements.push(...settlementsDay);
            weekDay.sendToAfas = !!weekDay.settlements.find(s => !!s.sent_to_afas);

            weekDay.week = week;
            weekDay.hasUnapprovedRealisations = realisationsDay
                .filter(r => ((!!r.comment_user_approved && !r.comment_user_approved_handled) || !r.approved) && !r.removed)?.length > 0;
            if (weekDay.sendToAfas) {
                week.sendToAfas = true;
            }
            week.weekDays.push(weekDay);
            bookdate.setDate(bookdate.getDate() + 1);


            interval++;

            if (interval > 7) {
                contractHoursLeft = 0;
                this.weeks.push(week);
                week = new Week();
                interval = 1;
            }


        }
        this.weeks.forEach(week => {
            week.weekDays.forEach(day => {
                this.userSubscriptions.add(day.fg.valueChanges.subscribe(values => {
                    this.calculateTotals(Object.keys(values));
                }));
            });
        });
        this.weeks[0].weekDays[0].fg.updateValueAndValidity();
        setTimeout(() => {
            document.body.appendChild(document.createElement('readyforpuppeteer'));
        });
    }

    calculateTotals(formControlKeys) {
        let totalOvertime = 0;
        for (const key of formControlKeys) {
            const totalFirst = this.weeks.flatMap(w => w.weekDays.flatMap(weekDay => weekDay.fgSecond.controls[key].value)).reduce((sum, current) => sum + current, 0);
            const totalSecond = this.weeks.flatMap(w => w.weekDays.flatMap(weekDay => weekDay.fg.controls[key].value)).reduce((sum, current) => sum + current, 0);
            const total = totalFirst + totalSecond;
            this.fcNamePeriodTotalMap.set(key, total);
            if (key.indexOf('overtime') !== -1) {
                totalOvertime += total;
            }
        }
        this.fcNamePeriodTotalMap.set('overtime', totalOvertime);
    }

    getRealisationTravelData(day: WeekDay, alwaysDvds = false) {
        const dvdsAdress = this.locationService.formatAddress(this.locationService.dvdsAddress);
        const userAdress = this.user.zipcode;
        const realisationStart = day.realisations.find(r => [RealisationHourtype.worktime, RealisationHourtype.education].includes(r.hourtype));

        if (realisationStart) {
            const origin = realisationStart?.user_planning?.origin ?? userAdress;
            if (realisationStart.planning?.location && !/\w+/g.test(realisationStart.planning?.location)) {
                realisationStart.planning.location = null;
            }
            let workBegin =
                realisationStart?.user_planning?.work_begin ??
                realisationStart?.planning_has?.transport_origin ??
                realisationStart.planning?.location ??
                realisationStart.project?.location ??
                dvdsAdress;
            if (workBegin === 'none') {
                workBegin = null;
            }
            if (alwaysDvds) {
                workBegin = dvdsAdress;
            }

            const realisationEnd = [...day.realisations].reverse().find(r => [RealisationHourtype.worktime, RealisationHourtype.education].includes(r.hourtype));
            const destination = realisationEnd?.user_planning?.destination ?? userAdress;
            let workEnd = realisationEnd.user_planning?.work_end ??
                realisationEnd.planning_has?.transport_destination ??
                realisationEnd.planning?.location ??
                realisationEnd.project?.location ??
                dvdsAdress;
            if (workEnd === 'none') {
                workEnd = null;
            }
            if (alwaysDvds) {
                workEnd = dvdsAdress;
            }

            const workBegin$ = workBegin ? this.locationService.autoRoute(origin, workBegin) : of({data: {kms: 0, from: '', to: ''}});
            const workEnd$ = workEnd ? this.locationService.autoRoute(workEnd, destination) : of({data: {kms: 0, from: '', to: ''}});
            return combineLatest(workBegin$, workEnd$, of({origin, workEnd, workBegin, destination, realisationStart, realisationEnd}));
        } else {
            return of([]);
        }
    }

}

class Week {
    weeknumber: string;
    weekDays: WeekDay[] = [];
    settling = false;
    sendToAfas = false;
}

class WeekDay {
    date: Date;
    type: string;
    fg: FormGroup<ControlsOf<ControlsOf<FgWeekDay>>>;
    fgSecond: FormGroup<ControlsOf<ControlsOf<FgWeekDay>>>;
    realisations: Realisation[];
    settlements: Settlement[];
    sendToAfas: boolean;
    hasUnapprovedRealisations: boolean;
    settling = false;
    week: Week;
    travelTo: string;
}

class FgWeekDay {
    dayType: string;
    begindate: string;
    enddate: string;
    interruptions: number;
    hours: number;
    pause: number;
    hoursToReward: number;
    notPaidHours: number;
    contractHours: number;
    remaining: number;
    supplemented: number;

    traveltime: number;
    travelcost: number;

    normal: number;
    bonus30: number;
    bonus35: number;
    bonus45: number;
    bonus50: number;

    overtime100: number;
    overtime125: number;
    overtime130: number;
    overtime135: number;
    overtime140: number;
    overtime145: number;
    overtime150: number;
    overtime200: number;

}

class timeTypeRealisationSettle {
    realisation: Realisation;
    minutes: number;
    from: Date;
    to: Date;
    type: TimeOnDay;
}

enum TimeOnDay {
    Normal, Night, Early
}
