import {Component, OnDestroy, OnInit} from '@angular/core';
import {EntitiesService} from '../services/entities/entities.service';
import {Entity} from '../classes/entity.class';
import {Router} from '@angular/router';
import {EntityType, EntityTypeCode} from '../services/entities/entity-type.class';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {Subscription} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {DialogService} from '../services/dialog/dialog.service';
import {EntityDetailComponent} from './entity-detail/entity-detail.component';
import {Utils} from '../utils.class';
import {Settings} from '../settings.class';
import {User} from '../classes/user.class';
import {UserService, UserType} from '../services/user/user.service';

@Component({
    selector: 'app-entities-overview',
    templateUrl: './entities-overview.component.html',
    styleUrls: ['./entities-overview.component.scss']
})
export class EntitiesOverviewComponent implements OnInit, OnDestroy {

    public entities: EntityViewGroup[] = [];
    public entityTypes: Map<number, EntityType>;
    today = new Date();
    Settings = Settings;
    users: User[];
    allEntities: Entity[];
    extraColsMap = new Map<number, number>();
    private subscriptions = new Subscription();

    constructor(private router: Router,
                public dialogRef: MatDialogRef<EntitiesOverviewComponent>,
                public dialogService: DialogService,
                private entitiesService: EntitiesService,
                private userService: UserService) {
    }

    showFinished(showFinished: boolean) {
        this.subscriptions.unsubscribe();
        this.subscriptions = new Subscription();
        this.subscriptions.add(this.entitiesService.getList().subscribe(entities => {
            this.entities = [];
            this.allEntities = entities;
            this.entityTypes.forEach((group, key) => {
                this.entities.push(new EntityViewGroup(group,
                    entities.filter(entity => !!entity.entitytypes.find(et => et.id === key))
                        .filter(entity => {
                            return showFinished
                                || !entity.enddate
                                || Utils.getTimeOrNull(entity.enddate) > this.today.getTime();
                        }).sort((a, b) => {
                        const getSorter = (entity: Entity) => ((entity.use_once ? 0 : 1000) + entity.order.find(t => t.entitytype_id === key).order);
                        return getSorter(a) - getSorter(b)
                    })
                ));
            });
        }));
    }


    ngOnInit() {
        this.subscriptions.add(this.entitiesService.getTypesMap().subscribe(data => {
            if (UserService.userHasRights(UserType.WORKPLANNER)) {
                this.entityTypes = data;
            } else if (UserService.userHasRights(UserType.VKMEXECUTOR)) {
                const entityTypes = data;
                const allowedTypes = [EntityTypeCode.VkmMateriaal, EntityTypeCode.VkmVoorbereiding];
                entityTypes.forEach((group, key) => {
                    if (!allowedTypes.includes(key)) {
                        entityTypes.delete(key);
                    }
                });
                this.entityTypes = entityTypes;
            }

            this.entityTypes.forEach(entityType => {
                const extraCols = [
                    !!entityType.has_lowloader,
                    !!entityType.has_truck,
                    !!entityType.has_preferred_team,
                    !!entityType.has_driver
                ].filter(p => !!p).length;
                this.extraColsMap.set(entityType.id, extraCols);
            });
            this.showFinished(false);

            this.subscriptions.add(this.userService.getList().subscribe(users => {
                this.users = users;
            }));
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    drop(event: CdkDragDrop<string[]>, array: EntityViewGroup) {
        moveItemInArray(array.entities, event.previousIndex, event.currentIndex);
        const sortingItems = [] as {
            order, entity_id, entitytype_id
        }[];
        array.entities.forEach((item, index) => {
            sortingItems.push({
                order: index,
                entity_id: item.id,
                entitytype_id: item.entitytype_id
            });
        });
        this.entitiesService.changeOrder(sortingItems, array.type.id).then();
    }

    public openEntity(entity: Entity) {
        this.dialogService.open(EntityDetailComponent, entity);
    }

    public addEntity(type: EntityType) {
        const entity = new Entity();
        entity.entitytypes = [type];
        this.dialogService.open(EntityDetailComponent, entity);
    }

    close() {
        this.dialogRef.close();
    }

}

export class EntityViewGroup {
    public type: EntityType;
    public entities: Entity[];

    constructor(type: EntityType, entities: Entity[]) {
        this.type = type;
        this.entities = entities;
    }

}
