import {Component, HostBinding, OnInit} from '@angular/core';
import {CodaltComponent} from '../codalt.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {environment} from '../../environments/environment';
import {Utils} from '../utils.class';
import {formatDate} from '@angular/common';
import {FormControl} from '@ngneat/reactive-forms';
import {UserService, UserType} from '../services/user/user.service';
import {User} from '../classes/user.class';
import {SettlementsService} from '../services/settlements.service';
import {ConfirmDialogService} from '../services/confirm-dialog-service/confirm-dialog.service';
import {LocalStorage} from '../storage.class';

@Component({
    selector: 'app-settlement',
    templateUrl: './settlement.component.html',
    styleUrls: ['./settlement.component.scss']
})
export class SettlementComponent extends CodaltComponent implements OnInit {

    @HostBinding('class.print') print = false;
    readonly = false;

    beginDate: Date;
    endDate: Date;
    today = new Date();

    year: number;
    period: number;
    paymentPeriod: number;
    paymentYear: number;

    fcUserId = new FormControl<number>();

    allEmployees: User[];
    employees: User[];
    user: User;

    screenContent: 'verrekening' | 'boekingsregels';
    downloadingPdf = false;

    daytypeCounts: { type: string; count: number; minutes: number; }[] = [];

    constructor(private userService: UserService,
                private settlementsService: SettlementsService,
                private activatedRoute: ActivatedRoute,
                private confirmDialogService: ConfirmDialogService,
                private router: Router,
                private title: Title) {
        super();
        this.title.setTitle('Periodeverrekening' + environment.titleAppend);
    }

    ngOnInit(): void {
        const queryParams = this.activatedRoute.snapshot.queryParams as { print };
        this.print = !!queryParams?.print;

        this.readonly = !UserService.userHasRights(UserType.SETTLEMENT) || this.print;

        if (!this.readonly) {
            this.subscriptions.add(this.fcUserId.valueChanges.subscribe(userId => {
                this.router.navigateByUrl(`periode/${this.screenContent}/${userId}/${this.period}/${this.year}${this.print ? '?print=true' : ''}`);
            }));
        }
        if (!UserService.userHasRights(UserType.SETTLEMENT)) {
            this.user = LocalStorage.getUser();
            this.fcUserId.setValue(this.user.id);
        }
        this.subscriptions.add(this.activatedRoute.params.subscribe((params: { userId, period, year, type }) => {
            this.screenContent = params.type;
            const monday = new Date();
            if (params?.year) {
                monday.setFullYear(params.year);
            }
            Utils.setTime(monday, 0, 0);
            monday.setMonth(0);
            monday.setDate(1);
            monday.setDate(monday.getDate() - ((monday.getDay() % 7) - 1));
            let today = new Date();
            if (!UserService.userHasRights(UserType.SETTLEMENT)) {
                today.setDate(today.getDate() - 56);
            }
            if (params.period) {
                const period = +params.period;
                today = new Date(monday);
                today.setDate(today.getDate() + (28 * (period - 1)));
            }
            let currentWeek = +formatDate(today, 'w', 'nl');
            if (today.getDay() === 0) {
                currentWeek -= 1;
            }
            currentWeek--;
            const periodStartWeek = (currentWeek - (currentWeek % 4));
            this.period = Math.floor((currentWeek / 4) + 1);
            if (!params.year && (new Date()).getMonth() == 11 && this.period === 1) {
                monday.setUTCDate(monday.getUTCDate() + (7 * 52));
            }
            this.beginDate = new Date(monday);
            this.beginDate.setDate(this.beginDate.getDate() + (periodStartWeek * 7));
            this.endDate = new Date(this.beginDate);
            this.endDate.setDate(this.endDate.getDate() + 28);
            this.year = this.period > 1 ? this.beginDate.getFullYear() : this.endDate.getFullYear();
            if (this.allEmployees) {
                this.filterAndSortEmployees();
            }
            if (params?.userId) {
                this.user = this.employees?.find(e => e.id === +params.userId);
                this.fcUserId.setValue(+params.userId);
            }

        }));

        this.subscriptions.add(this.userService.getList(true, false, false).subscribe(users => {
            this.allEmployees = users;
            this.filterAndSortEmployees();
            if (!this.fcUserId.value) {
                this.fcUserId.setValue(this.employees.find(u => !!u.afas_employee_id).id);
            } else if (!this.user) {
                this.user = this.employees?.find(e => e.id === +this.fcUserId.value);
            }
        }));
    }

    filterAndSortEmployees() {
        const sortString = (z: User) => (z.group ?? 'ZZZZ') + (z.lastname ?? '') + (z.firstname ?? '');
        this.employees = this.allEmployees
            .filter(u => !u.deleted_at || (new Date(u.deleted_at)).getTime() > this.beginDate.getTime())
            .sort((a, b) => sortString(a).localeCompare(sortString(b)));
    }

    pdf() {
        this.downloadingPdf = true;
        if (this.readonly) {
            this.settlementsService.periodSettlementSinglePdf(this.year, this.period, this.user.id).subscribe((result) => {
                this.confirmDialogService.confirm('Exporteren als PDF', result.message, 'Sluiten', null).then(() => {

                });
                this.downloadingPdf = false;
            }, () => {
                this.downloadingPdf = false;
            });
        } else {
            this.confirmDialogService.confirm('Kies een export',
                `Wil je alle periodeverrekening per e-mail of alleen de verrekening van ${this.user.name}?`,
                `Alleen ${this.user.firstname ?? this.user.name}`,
                'Iedereen').then(() => {
                this.settlementsService.periodSettlementSinglePdf(this.year, this.period, this.user.id).subscribe((result) => {
                    this.confirmDialogService.confirm('Exporteren als PDF', result.message, 'Sluiten', null).then(() => {

                    });
                    this.downloadingPdf = false;
                }, () => {
                    this.downloadingPdf = false;
                });
            }, () => {
                this.settlementsService.periodSettlementAllPdf(this.year, this.period).subscribe((result) => {
                    this.confirmDialogService.confirm('Exporteren als PDF', result.message, 'Sluiten', null).then(() => {

                    });
                    this.downloadingPdf = false;
                }, () => {
                    this.downloadingPdf = false;
                });
            });
        }
    }

    customSearchFn(term: string, item: User) {
        return item.name.toLowerCase().indexOf(term.toLowerCase()) !== -1
            || item.afas_employee_id.padStart(4, '0').indexOf(term) !== -1
            || item.group.toLowerCase().indexOf(term.toLowerCase()) !== -1;
    }


    prevEmployee() {
        const index = this.employees.findIndex(e => e.id === this.fcUserId.value);
        if (index) {
            this.router.navigateByUrl(`periode/${this.screenContent}/${this.employees[index - 1].id}/${this.period}/${this.year}`);
        }
    }

    nextEmployee() {
        const index = this.employees.findIndex(e => e.id === this.fcUserId.value);
        if (this.employees[index + 1] !== undefined) {
            this.router.navigateByUrl(`periode/${this.screenContent}/${this.employees[index + 1].id}/${this.period}/${this.year}`);
        }
    }

    next() {
        let period = this.period + 1;
        let year = this.year;
        if (period > 13) {
            period = 1;
            year += 1;
        }

        if (!UserService.userHasRights(UserType.SETTLEMENT)) {
            let today = new Date();
            today.setDate(today.getDate() - 56);
            let currentWeek = +formatDate(today, 'w', 'nl');
            if (today.getDay() === 0) {
                currentWeek -= 1;
            }
            currentWeek--;
            if (period > Math.floor((currentWeek / 4) + 1) && year === today.getFullYear()) {
                return;
            }
        }

        this.router.navigateByUrl(`periode/${this.screenContent}/${this.user.id}/${period}/${year}`);
    }

    prev() {
        let period = this.period - 1;
        let year = this.year;
        if (period < 1) {
            period = 13;
            year -= 1;
        }
        this.router.navigateByUrl(`periode/${this.screenContent}/${this.user.id}/${period}/${year}`);
    }

}
