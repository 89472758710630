import {Component, HostBinding, HostListener, OnInit} from '@angular/core';

import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {Planning} from '../../classes/planning.class';
import {Project} from '../../classes/project.class';
import {debounceTime, first, startWith, throttleTime} from 'rxjs/operators';
import {asyncScheduler, fromEvent, Subject} from 'rxjs';
import {ProjectService} from '../../services/project.service';
import {AutocompleteService, AutocompleteType} from '../../services/planning/autocomplete.service';
import {User} from '../../classes/user.class';
import {UserService, UserType} from '../../services/user/user.service';
import {LocationService} from '../../services/location.service';
import {EntitiesService} from '../../services/entities/entities.service';
import {EntityTypeCode} from '../../services/entities/entity-type.class';
import {Entity} from '../../classes/entity.class';
import {PlanningHasEntity} from '../../classes/planning-has-entity.class';
import {SettleGroupPrice, VkmMaterialCalculation, VkmObjectCalculation, VkmService} from '../../services/vkm.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PlanningService} from '../../services/planning/planning.service';
import {SortTabsPipe} from './sort-tabs.pipe';
import {DateAdapter} from '@angular/material/core';
import {DateFullMonthAdapter} from '../../date-adapters/date-full-month-adapter';
import {DayTimeOptions} from '../../classes/day-time-options';
import {TimeOption} from '../../classes/time-option';
import {ConfirmDialogService} from '../../services/confirm-dialog-service/confirm-dialog.service';
import {NotSettledCountPipe} from './not-settled-count.pipe';
import {Utils} from '../../utils.class';
import {CodaltComponent} from '../../codalt.component';
import {Workorderline} from '../../classes/workorderline';
import {MatDialog} from '@angular/material/dialog';
import {VkmChecklistComponent} from '../vkm-checklist/vkm-checklist.component';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {VkmAnswer} from '../../classes/vkm-answer';
import {HttpResponse} from '@angular/common/http';
import {saveAs} from 'file-saver';
import {NameTabsPipe} from './name-tabs.pipe';
import {VkmChecklistOverviewComponent} from '../vkm-checklist-overview/vkm-checklist-overview.component';
import {VkmExcelExportService} from '../../services/vkm-excel-export.service';

@Component({
    selector: 'app-vkm-planning',
    templateUrl: './vkm-planning.component.html',
    styleUrls: ['./vkm-planning.component.scss'],
    providers: [{
        provide: DateAdapter, useClass: DateFullMonthAdapter
    }]
})
export class VkmPlanningComponent extends CodaltComponent implements OnInit {

    @HostBinding('class.print')
    print = false;

    downloadingPdf = false;

    backTo = 'vkm-werkorders';

    infoBar = false;
    mayReload = false;
    saving = false;
    saved = false;
    currentTabIndex = -1;

    projects: Project[];
    searchAfasProjects$ = new Subject<string>();
    autocompleteContractors: string[];
    searchLocations$ = new Subject<string>();

    executors: User[];
    mechanics: User[];
    usersMap: Map<number, User>;

    form: FormGroup<{
        id: FormControl<number>,
        contractor: FormControl<string>,
        afas_project_id: FormControl<string>,
        location: FormControl<string>,
        planning_vkm: FormArray<FormGroup<FgPlanningHas>>,
        updated_at: FormControl<Date>,
        description: FormControl<string>
    }>;

    addresses: {
        addressLine,
        adminDistrict,
        adminDistrict2,
        countryRegion,
        formattedAddress,
        locality,
        postalCode
    }[];

    fieldNameByType = new Map<string, Map<string, string>>([
        ['MWO', new Map<string, string>([
            ['planned', 'Vkm-plan'],
            ['count', 'Geplaatst'],
            ['date', 'Leverdatum']
        ])],
        ['DWO', new Map<string, string>([
            ['lost', 'Kwijt'],
            ['count', 'Retour'],
            ['date', 'Retourdatum']
        ])]
    ]);

    fieldsByType = new Map<string, Map<string, string>>([
        ['MWO', new Map<string, string>([
            ['planned', 'count_planned'],
            ['count', 'count_placed'],
            ['date', 'date_placed']
        ])],
        ['DWO', new Map<string, string>([
            ['lost', 'count_lost'],
            ['count', 'count_removed'],
            ['date', 'date_removed']
        ])]
    ]);

    materiaal: Entity[];
    preparation: Entity[];
    entitiesMap: Map<number, Entity>;
    teamEntities: Entity[];

    objectCalculations: VkmObjectCalculation[];
    materialCalculations: VkmMaterialCalculation[];
    totalPrices: SettleGroupPrice[];
    totalPrice: number;

    startTimes = new Map<FormGroup<FgPlanningHas>, DayTimeOptions[]>();
    endTimes = new Map<FormGroup<FgPlanningHas>, DayTimeOptions[]>();

    checklistAnswers: VkmAnswer[];
    answeredChecklistMap = new Map<string, boolean>();

    settleGroupsOnlyForExecutor = ['Aanvragen', 'Tekeningen'];

    @HostListener('document:keydown', ['$event'])
    onChangeKeypress(event: KeyboardEvent) {
        const activeElement = document.activeElement;
        const table = activeElement.closest('table');

        if (!table) {
            return; // Geen tabel gevonden, doe niets
        }

        // Beperk de zoekopdracht tot invoervelden in de tabel
        const inputs = Array.from(table.querySelectorAll('input, textarea, select, [tabindex]')) as HTMLElement[];
        const currentIndex = inputs.findIndex(input => input === activeElement);

        let elementToFocus = null;
        if (currentIndex !== -1) {

            // Controleer of het actieve element in een ng-select zit
            const ngSelectWrapper = activeElement.closest('.ng-select');
            if (ngSelectWrapper) {
                // Blokkeer pijltjestoetsen binnen een ng-select zonder focus te verplaatsen
                if (['ArrowUp', 'ArrowDown'].includes(event.key)) {
                    event.preventDefault();
                    return;
                }
            }

            // Verwerk de pijltjestoetsen
            if (event.key === 'ArrowRight') {
                event.preventDefault();
                elementToFocus = inputs[currentIndex + 1];
            } else if (event.key === 'ArrowLeft') {
                event.preventDefault();
                elementToFocus = inputs[currentIndex - 1];
            } else if (event.key === 'ArrowDown') {
                event.preventDefault();
                const columns = this.getColumnsCount(table);
                elementToFocus = inputs[currentIndex + columns];
            } else if (event.key === 'ArrowUp') {
                event.preventDefault();
                const columns = this.getColumnsCount(table);
                elementToFocus = inputs[currentIndex - columns];
            }

            if (elementToFocus) {
                elementToFocus.focus();
            }
        }

    }

    getColumnsCount(table: HTMLElement): number {
        // Bereken het aantal kolommen in de tabel (aangepast voor jouw specifieke tabelstructuur)
        const firstRow = table.querySelector('tr');
        if (!firstRow) {
            return 1; // Fallback: minimaal 1 kolom
        }
        // Zoek naar alle cellen in de eerste rij
        const cells = firstRow.querySelectorAll('td, th');
        return cells.length; // Retourneer het aantal kolommen
    }

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private confirmDialog: ConfirmDialogService,
                public dialog: MatDialog,
                private planningService: PlanningService,
                private projectService: ProjectService,
                private autocompleteService: AutocompleteService,
                private locationService: LocationService,
                private userService: UserService,
                private entitiesService: EntitiesService,
                private vkmExcelExportService: VkmExcelExportService,
                private vkmService: VkmService) {
        super();
    }

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any) {
        if (this.form?.dirty) {
            $event.returnValue = true;
        }
    }

    openChangesBackActionCheck(): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.form.dirty) {
                this.confirmDialog.confirm(
                    'Niet opgeslagen wijzigingen',
                    `Wilt u de niet opgeslagen wijzigingen verwerpen?`,
                    'Hier blijven',
                    'Wijzigingen verwerpen').then(() => {
                    resolve(false);
                }, () => {
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
    }

    tabChanged(event: MatTabChangeEvent) {
        this.getChecklistAnswers();
    }

    pdf() {
        const fg = this.form.controls.planning_vkm.controls[this.currentTabIndex - (UserService.userHasRights(UserType.VKMEXECUTOR) ? 1 : 0)];
        let planningHas = `${fg?.value?.id}`;
        if (!fg && this.currentTabIndex > 0) {
            planningHas = 'afrekenstaat';
        }
        this.downloadingPdf = true;
        this.subscriptions.add(this.vkmService.getPdf(this.form.value.id, planningHas).subscribe((data: HttpResponse<any>) => {
            let filename = `vkm_${this.form.value.afas_project_id}_`;
            if (fg) {
                filename += (new (NameTabsPipe)).transform(fg, this.form.controls.planning_vkm.controls);
            } else {
                filename += 'afrekenstaat';
            }
            saveAs(data, `${filename}.pdf`);
            this.downloadingPdf = false;
        }, () => {
            this.downloadingPdf = false;
        }));
    }

    excel() {
        this.subscriptions.add(this.vkmService.countsAndPrices(this.form.value?.id).subscribe(calculations => {
            this.vkmExcelExportService.createExcelExport(calculations.data, this.entitiesMap);
        }));
    }

    checklistOverview() {
        const fg = this.form.controls.planning_vkm.controls[this.currentTabIndex - (UserService.userHasRights(UserType.VKMEXECUTOR) ? 1 : 0)];
        const ref = this.dialog.open(VkmChecklistOverviewComponent, {
            data: {
                planning_has_id: fg.value.id,
                usersMap: this.usersMap
            },
            maxWidth: '100vw'
        });
        const subs = ref.afterClosed().subscribe(() => {
            this.getChecklistAnswers();
            subs.unsubscribe();
        });
    }

    setCountTo(entityName: string, value, planning: FormGroup<FgPlanningHas>) {
        const entity = this.materiaal.find(m => m.name.includes(entityName));
        const workorderObject = planning.controls.workorderObjects.controls.find(w => w.value.entity_id === entity.id);
        if (workorderObject) {
            workorderObject.controls.count_planned.setValue(value);
            workorderObject.controls.count_planned.markAsDirty();
        }
    }

    getChecklistAnswers() {
        const fg = this.form.controls.planning_vkm.controls[this.currentTabIndex - (UserService.userHasRights(UserType.VKMEXECUTOR) ? 1 : 0)];
        if (fg) {
            this.subscriptions.add(this.vkmService.getAnswers(fg.value.id).subscribe(answers => {
                this.checklistAnswers = answers.data;
                ['controlepunten', 'werkoverdracht'].forEach(type => {
                    this.answeredChecklistMap.set(type, this.checklistAnswers.filter(a => a.type === type)?.length > 0);
                });

            }));
        }
    }

    removeWorkorder(planning: FormGroup<FgPlanningHas>) {
        this.confirmDialog.confirm(
            `${planning.value.type} verwijderen`,
            `Weet je zeker dat je deze ${planning.value.type} wilt verwijderen?`,
            'Verwijderen', 'Behouden').then(() => {

            const index = this.form.controls.planning_vkm.controls.indexOf(planning);
            this.form.controls.planning_vkm.removeAt(index);
            this.form.controls.planning_vkm.markAsDirty();

        }, () => {
        });
    }

    calcNotSettled(fgWl: FormGroup<FgWorkorderLine>, field: string) {
        if ((new NotSettledCountPipe()).transform(this.form.controls.planning_vkm, fgWl, field, false)) {
            fgWl.get(field).setValue(0);
        }
        if ((new NotSettledCountPipe()).transform(this.form.controls.planning_vkm, fgWl, field)) {
            fgWl.get(field).setValue((new NotSettledCountPipe()).transform(this.form.controls.planning_vkm, fgWl, field));
            fgWl.get(field).markAsDirty();
        }
    }

    notSettledAll(fArr: FormArray<FormGroup<FgWorkorderLine>>, field: string) {
        if (UserService.userHasRights(UserType.VKMMECHANIC)) {
            fArr.controls.forEach(fgWl => {
                if (!fgWl.value.count_removed) {
                    this.calcNotSettled(fgWl, field);
                }
            });
        }
    }

    setPlanned(fgWl: FormGroup<FgWorkorderLine>, field: string) {
        fgWl.get(field).setValue(fgWl.getRawValue().count_planned);
        fgWl.get(field).markAsDirty();
    }

    setPlannedAll(fArr: FormArray<FormGroup<FgWorkorderLine>>, field: string) {
        if (UserService.userHasRights(UserType.VKMMECHANIC)) {
            fArr.controls.forEach(fgWl => {
                this.setPlanned(fgWl, field);
            });
        }
    }

    setDates(planningHas: FormGroup<FgPlanningHas>, field: string) {
        if (UserService.userHasRights(UserType.VKMMECHANIC)) {
            planningHas.controls.workorderObjects.controls.forEach(fg => {
                if (!fg.get(field).value && (fg.value.count_placed || fg.value.count_removed || fg.value.date_removed || fg.value.date_placed)) {
                    fg.get(field).setValue(Utils.setTime(new Date(planningHas.value.begindate), 0, 0));
                }
            });
            planningHas.markAsDirty();
        }
    }

    setDate(planningHas: FormGroup<FgPlanningHas>, fgWl: FormGroup<FgWorkorderLine>, field: string) {
        if (fgWl.value.count_placed || fgWl.getRawValue().count_planned || fgWl.value.count_removed) {
            fgWl.get(field).setValue(Utils.setTime(new Date(planningHas.value.begindate), 0, 0));
            fgWl.markAsDirty();
        }
    }

    genStartAndEndTimes(planningHas: FormGroup<FgPlanningHas>) {
        if (planningHas.getRawValue().date.date) {
            const startDate = new Date(planningHas.controls.date.controls.date.value);

            function genTimes(fromDate, toDate) {
                const times: Date[] = [];
                const countDate = new Date(fromDate);
                while (countDate.getTime() <= toDate.getTime()) {
                    times.push(new Date(countDate));
                    countDate.setUTCMinutes(countDate.getUTCMinutes() + 30);
                }
                const dayOptionsList: DayTimeOptions[] = [];
                let dayOptions = new DayTimeOptions();
                times.forEach((time, i) => {
                    if (!dayOptions.datetime || dayOptions.datetime.getDay() !== time.getDay() || i === times.length - 1) {
                        if (dayOptions.datetime) {
                            dayOptionsList.push(dayOptions);
                        }
                        dayOptions = new DayTimeOptions();
                        dayOptions.datetime = time;
                    }
                    const timeOption = new TimeOption(time);
                    dayOptions.options.push(timeOption);
                });
                return dayOptionsList;
            }

            const dayOptionsListStart = genTimes(Utils.setTime(new Date(startDate), 0, 0), Utils.setTime(new Date(startDate), 24, 0));
            this.startTimes.set(planningHas, dayOptionsListStart);

            const startEndTimes = new Date(planningHas.controls.date.value.begintime);
            if (startEndTimes.getMinutes() > 30) {
                startEndTimes.setHours(startEndTimes.getHours() + 1);
                startEndTimes.setMinutes(0);
            } else {
                startEndTimes.setMinutes(30);
            }
            const endEndTimes = new Date(planningHas.controls.date.value.begintime);
            endEndTimes.setDate(endEndTimes.getDate() + 1);

            const dayOptionsListEnd = genTimes(Utils.setTime(new Date(startDate), startEndTimes.getHours(), startEndTimes.getMinutes()), endEndTimes);
            this.endTimes.set(planningHas, dayOptionsListEnd);
        } else {
            this.startTimes.set(planningHas, []);
            this.endTimes.set(planningHas, []);
        }
    }

    checklist(type: 'controlepunten' | 'werkoverdracht', planning_has_id) {
        const ref = this.dialog.open(VkmChecklistComponent, {
            data: {
                type,
                planning_has_id,
                answers: this.checklistAnswers
            },
            maxWidth: '100vw'
        });
        const subs = ref.afterClosed().subscribe(() => {
            this.getChecklistAnswers();
            subs.unsubscribe()
        });
    }

    ngOnInit(): void {

        this.subscriptions.add(fromEvent(document.getElementById('main-container'), 'scroll')
            .pipe(throttleTime(100, asyncScheduler, {leading: false, trailing: true}))
            .subscribe((e: Event) => {
                this.showInfoBar();
            }));

        this.subscriptions.add(this.activatedRoute.queryParams.subscribe((q: { print: string }) => {
            this.print = q.print === 'true';
        }));

        this.subscriptions.add(this.activatedRoute.params.subscribe((params: { id, fromDate, toDate, mechanicId, backTo, planningHas }) => {
            if (params.backTo) {
                this.backTo = params.backTo;
            }
            if (params.id && params.id != 'new') {
                this.getPlanning(+params.id, params.planningHas);
            } else {
                this.createForm(null, params.fromDate, params.toDate, params.mechanicId ? +params.mechanicId : null);
            }
        }));

        this.subscriptions.add(this.userService.getByType(UserType.EXECUTOR).subscribe(users => this.executors = users));
        this.subscriptions.add(this.userService.getByType(UserType.VKMMECHANIC).subscribe(users => this.mechanics = users));
        this.subscriptions.add(this.userService.getMap().subscribe(map => this.usersMap = map));

        this.subscriptions.add(this.searchAfasProjects$.pipe(debounceTime(200)).subscribe(search => {
            this.subscriptions.add(this.projectService.searchProjects(search).subscribe((projectResponse) => {
                this.projects = projectResponse.data?.filter(p => !!p.parent_id);
            }));
        }));

        this.subscriptions.add(this.searchLocations$.pipe(debounceTime(200)).subscribe(search => {
            const addresses = [];
            this.subscriptions.add(this.locationService.getLocations(search + ' Nederland').subscribe(result => {
                result.resourceSets.forEach(res => {
                    res.resources.forEach(address => {
                        addresses.push(address.address);
                    });
                });
                this.addresses = addresses;
            }));
        }));

        this.subscriptions.add(this.entitiesService.getByType(EntityTypeCode.VkmMateriaal).subscribe(entities => {
            this.materiaal = entities;
        }));
        this.subscriptions.add(this.entitiesService.getByType(EntityTypeCode.VkmVoorbereiding).subscribe(entities => {
            this.preparation = entities;
        }));
        this.subscriptions.add(this.entitiesService.getMap().pipe(debounceTime(500)).subscribe(entitiesMap => {
            this.entitiesMap = entitiesMap;
        }));
        this.subscriptions.add(this.entitiesService.getByType([EntityTypeCode.VKMPloeg, EntityTypeCode.VKMVakman, EntityTypeCode.VKMWerkopdracht]).subscribe(entities => {
            this.teamEntities = entities;
            const none = this.teamEntities.find(e => e.entitytypes.map(et => et.id).includes(EntityTypeCode.VKMWerkopdracht));
            if (none) {
                none.name = '- Geen ploeg -';
            }
        }));
    }

    getPlanning(id?: number, openPlanningHas?: any) {
        let first = true;
        this.subscriptions.add(this.planningService.getSingle(id).subscribe(planning => {
            if (!this.form || this.mayReload) {
                this.mayReload = false;
                this.createForm(planning);
                if (first) {
                    if (openPlanningHas) {
                        const planningVkm = (new SortTabsPipe()).transform(this.form.controls.planning_vkm.controls);
                        const index = openPlanningHas === 'afrekenstaat' ? planningVkm.length : planningVkm.findIndex(p => p.value.id === +openPlanningHas);
                        setTimeout(() => {
                            this.currentTabIndex = index + (UserService.userHasRights(UserType.VKMEXECUTOR) ? 1 : 0);
                            setTimeout(() => {
                                document.body.appendChild(document.createElement('readyforpuppeteer'));
                            });
                        });
                    } else {
                        if (this.currentTabIndex < 1 && UserService.userHasRights(UserType.VKMEXECUTOR)) {
                            setTimeout(() => {
                                this.currentTabIndex = 0;
                                setTimeout(() => {
                                    this.currentTabIndex = 1;
                                    this.getChecklistAnswers();
                                });
                            });
                        }
                    }
                }
                first = false;
            }
        }));
    }

    addWorkorder(type, fromDate?: any, toDate?: any, mechanicId?: number) {
        const wo = this.createWorkorder(null, type, fromDate, toDate, mechanicId);
        this.form.controls.planning_vkm.push(wo);
        wo.markAsDirty();

        setTimeout(() => {
            this.currentTabIndex = (new SortTabsPipe()).transform(this.form.controls.planning_vkm.controls).indexOf(wo) + 1;
        });
    }

    addWorkoderLine(fg: FormGroup<FgPlanningHas>, formArray: FormArray<FormGroup<FgWorkorderLine>>) {
        formArray.push(this.createWorkoderLine());
    }

    addWorkoderLineText(formArray: FormArray<FormGroup<FgWorkorderLine>>) {
        formArray.push(this.createWorkoderLine(null, 'text'));
    }

    removeEmptyLines(formArray: FormArray<FormGroup<FgWorkorderLine>>) {
        for (let i = formArray.controls.length - 1; i >= 0; i--) {
            const fgWl = formArray.controls[i];
            if (!fgWl.value.description && !fgWl.value.count_placed && !fgWl.getRawValue().count_planned && !fgWl.value.count_removed && !fgWl.value.date_removed && !fgWl.value.date_placed) {
                const index = formArray.controls.indexOf(fgWl);
                formArray.removeAt(index);
            }
        }
        formArray.markAsDirty();
    }

    resetDates(fg: FormGroup<FgPlanningHas>) {
        fg.controls.enddate.reset();
        fg.controls.begindate.reset();
        fg.controls.date.reset();
        fg.markAsDirty();
    }

    createWorkorder(ph: PlanningHasEntity, type?: 'MWO' | 'DWO', fromDate?: any, toDate?: any, mechanicId?: number): FormGroup<FgPlanningHas> {
        let beginDate = ph?.begindate ?? fromDate ?? Utils.setTime(new Date(), new Date().getHours(), 0);
        let endDate = ph?.enddate ?? toDate ?? Utils.setTime(new Date(), new Date().getHours() + 1, 0);

        if (!fromDate) {
            const otherFromType = this.form?.value?.planning_vkm.filter(p => p.type === type);
            if (otherFromType?.length > 0) {
                const last = otherFromType[otherFromType.length - 1];
                beginDate = new Date(last.begindate);
            }
        }
        if (!toDate) {
            const otherFromType = this.form?.value?.planning_vkm.filter(p => p.type === type);
            if (otherFromType?.length > 0) {
                const last = otherFromType[otherFromType.length - 1];
                endDate = new Date(last.enddate);
            }
        }

        const fg = new FormGroup<FgPlanningHas>({
            id: new FormControl(ph?.id),
            type: new FormControl(ph?.type ?? type),
            drawing: new FormControl(ph?.drawing ?? this.form?.value?.planning_vkm.find(p => !!p.drawing)?.drawing),
            vg_plan: new FormControl(ph?.vg_plan ?? this.form?.value?.planning_vkm.find(p => !!p.vg_plan)?.vg_plan),
            comment: new FormControl(ph?.comment),
            performer_id: new FormControl(ph?.performer_id),
            mechanic_id: new FormControl(ph?.mechanic_id ?? mechanicId),
            mechanics: new FormControl(ph?.mechanics?.map(m => m.id) ?? []),
            date: new FormGroup({
                date: new FormControl(ph?.id ? ph.begindate : beginDate),
                begintime: new FormControl(ph?.id ? new Date(ph.begindate).getTime() : new Date(beginDate).getTime()),
                endtime: new FormControl(ph?.id ? new Date(ph.enddate).getTime() : new Date(endDate).getTime())
            }),
            begindate: new FormControl(ph?.id ? ph.begindate : beginDate),
            enddate: new FormControl(ph?.id ? ph.enddate : endDate),
            workorderObjects: new FormArray(ph?.workorder_lines
                ?.filter(wl => wl.entity?.entitytypes.map(et => et.id).includes(EntityTypeCode.VkmMateriaal) || wl.comment === 'text')
                .map(wl => this.createWorkoderLine(wl)) ?? this.createDefaultObjects(type)
            ),
            workorderMaterials: new FormArray(ph?.workorder_lines
                ?.filter(wl => wl.entity?.entitytypes.map(et => et.id).includes(EntityTypeCode.VkmVoorbereiding))
                .map(wl => this.createWorkoderLine(wl)) ?? this.createDefaultPreperations()
            ),
            _coupling_clamps: new FormControl(0),
            _ground_pot: new FormControl(0),
            updated_at: new FormControl(ph?.updated_at)
        });
        if (!UserService.userHasRights(UserType.VKMMECHANIC)) {
            fg.disable();
        } else if (!UserService.userHasRights(UserType.VKMEXECUTOR)) {
            fg.controls.performer_id.disable();
            fg.controls.mechanic_id.disable();
            fg.controls.date.disable();
            fg.controls.drawing.disable();
            fg.controls.vg_plan.disable();
            fg.controls.comment.disable();
        }
        this.genStartAndEndTimes(fg);
        this.subscriptions.add(fg.controls.workorderObjects.valueChanges.pipe(startWith(fg.value.workorderObjects)).subscribe(workorderObjects => {
            let coupling_clamps = 0;
            let ground_pot = 0;
            workorderObjects.forEach(workorderObject => {
                let entity = ph?.workorder_lines?.find(wl => wl.entity_id === workorderObject.entity_id).entity;
                if (!entity && this.entitiesMap) {
                    entity = this.entitiesMap.get(workorderObject.entity_id);
                }
                if (entity) {
                    coupling_clamps += (entity?.coupling_clamps ?? 0) * workorderObject.count_planned;
                    ground_pot += (entity?.ground_pot ?? 0) * workorderObject.count_planned;
                }
            });
            fg.controls._ground_pot.setValue(ground_pot);
            fg.controls._coupling_clamps.setValue(coupling_clamps);
        }));
        this.subscriptions.add(fg.controls.date.valueChanges.subscribe(date => {
            const newBeginTime = new Date(date.date);
            const begintime = new Date(date.begintime);
            newBeginTime.setHours(begintime.getHours());
            newBeginTime.setMinutes(begintime.getMinutes());
            const newEndTime = new Date(date.date);
            const endtime = new Date(date.endtime);
            newEndTime.setHours(endtime.getHours());
            newEndTime.setMinutes(endtime.getMinutes());
            fg.controls.date.controls.begintime.setValue(newBeginTime.getTime(), {emitEvent: false});
            fg.controls.date.controls.endtime.setValue(newEndTime.getTime(), {emitEvent: false});
            if (fg.value.date.date) {
                fg.controls.begindate.setValue(new Date(newBeginTime.getTime()), {emitEvent: false});
                fg.controls.enddate.setValue(new Date(newEndTime.getTime()), {emitEvent: false});
            } else {
                fg.controls.begindate.setValue(null);
                fg.controls.enddate.setValue(null);
            }
            this.genStartAndEndTimes(fg);
        }));

        return fg;
    }

    createWorkoderLine(wl?: Workorderline, comment?: string) {
        const fg = new FormGroup<FgWorkorderLine>({
            id: new FormControl(wl?.id),
            planning_has_id: new FormControl(wl?.planning_has_id),
            description: new FormControl(wl?.description),
            entity_id: new FormControl(wl?.entity_id),
            count_planned: new FormControl(wl?.entity?.unit === 'uur' || !wl?.count_planned ? wl?.count_planned : Math.round(wl?.count_planned)),
            count_placed: new FormControl(wl?.entity?.unit === 'uur' || !wl?.count_placed ? wl?.count_placed : Math.round(wl?.count_placed)),
            count_removed: new FormControl(wl?.entity?.unit === 'uur' || !wl?.count_removed ? wl?.count_removed : Math.round(wl?.count_removed)),
            count_lost: new FormControl(wl?.entity?.unit === 'uur' || !wl?.count_lost ? wl?.count_lost : Math.round(wl?.count_lost)),
            date_placed: new FormControl(wl?.date_placed),
            date_removed: new FormControl(wl?.date_removed),
            comment: new FormControl(wl?.comment ?? comment),
            checked_at: new FormControl(wl?.checked_at),
            checked_by: new FormControl(wl?.checked_by),
            order: new FormControl(wl?.order),
            updated_at: new FormControl(wl?.updated_at)
        });

        if (this.settleGroupsOnlyForExecutor.includes(wl.entity?.settle_group) && !UserService.userHasRights(UserType.VKMEXECUTOR)) {
            fg.disable();
        }

        this.subscriptions.add(fg.controls.count_placed.valueChanges.pipe(debounceTime(500)).subscribe(count => {
            if (!count) {
                fg.controls.date_placed.reset();
            }
        }));
        this.subscriptions.add(fg.controls.count_planned.valueChanges.subscribe(count => {
            if (fg.value.count_planned === fg.value.count_placed) {
                fg.controls.count_placed.setValue(count);
            }
        }));

        if (!UserService.userHasRights(UserType.VKMEXECUTOR)) {
            fg.controls.count_planned.disable();
        }

        return fg;
    }

    removeWorkorderLine(formArray: FormArray<FormGroup<FgWorkorderLine>>, fgWl: FormGroup<FgWorkorderLine>) {
        const entity = this.entitiesMap.get(fgWl.value.entity_id);
        if (!fgWl.value.count_placed && !fgWl.getRawValue().count_planned && !fgWl.value.count_removed && !fgWl.value.date_removed && !fgWl.value.date_placed) {
            const index = formArray.controls.indexOf(fgWl);
            formArray.removeAt(index);
        } else {
            this.confirmDialog.confirm(
                `Object verwijderen`,
                `Weet je zeker dat je <b>${entity.name}</b> wilt verwijderen?`,
                'Verwijderen', 'Behouden').then(() => {
                const index = formArray.controls.indexOf(fgWl);
                formArray.removeAt(index);
            }, () => {
            });
        }
        formArray.markAsDirty();
    }

    addAllMissingObjects(planning: FormGroup<FgPlanningHas>) {
        const items = this.createDefaultObjects(planning.value.type, true);
        items.forEach((item) => {
            if (!planning.value.workorderObjects.find(wob => wob.entity_id === item.value.entity_id)) {
                planning.controls.workorderObjects.push(item);
            }
        });
    }

    createDefaultObjects(type?: 'MWO' | 'DWO', all?: boolean) {
        let materiaal = this.materiaal;
        if (type === 'DWO' && !all) {
            const mwos = this.form.getRawValue().planning_vkm
                .filter(p => p.type === 'MWO')
                .map(m => m.workorderObjects)
                .flat()
                .filter(m => m.count_planned || m.count_placed)
                .map(m => m.entity_id);
            materiaal = this.materiaal.filter(m => mwos.includes(m.id));
        }
        return materiaal.map(m => {
            return this.createWorkoderLine({
                entity_id: m.id
            });
        });
    }

    createDefaultPreperations() {
        return this.preparation.map(m => {
            return this.createWorkoderLine({
                entity_id: m.id
            });
        });
    }

    countAndPrices() {
        if (this.form.value?.id && UserService.userHasRights(UserType.VKMEXECUTOR)) {
            this.subscriptions.add(this.vkmService.countsAndPrices(this.form.value?.id).subscribe(calculations => {
                this.objectCalculations = calculations.data.objects;
                this.materialCalculations = calculations.data.materials;
                this.totalPrices = calculations.data.totalPrices;
                this.totalPrice = calculations.data.totalPrice;
            }));
        }
    }

    createForm(planning?: Planning, fromDate?: any, toDate?: any, mechanicId?: number) {
        this.form = new FormGroup({
            id: new FormControl(planning?.id),
            updated_at: new FormControl(planning?.updated_at),
            afas_project_id: new FormControl<string>(planning?.afas_project_id),
            contractor: new FormControl<string>(planning?.contractor),
            location: new FormControl<string>(planning?.location),
            planning_vkm: new FormArray(planning?.planning_vkm?.map(ph => this.createWorkorder(ph)) ?? []),
            description: new FormControl(planning?.description)
        });
        if (!UserService.userHasRights(UserType.VKMEXECUTOR)) {
            this.form.controls.afas_project_id.disable();
            this.form.controls.contractor.disable();
            this.form.controls.location.disable();
        }
        if (!planning?.id) {
            this.subscriptions.add(this.entitiesService.getByType(EntityTypeCode.VkmMateriaal).pipe(debounceTime(500), first()).subscribe(() => {
                this.addWorkorder('MWO', fromDate, toDate, mechanicId);
            }));
        }

        if (!this.form.value.id) {
            const projectIdSubs = this.form.controls.afas_project_id.valueChanges.subscribe(projectId => {
                this.subscriptions.add(this.vkmService.idByProject(projectId).subscribe(planningId => {
                    this.mayReload = true;
                    if (planningId.data) {
                        this.confirmDialog.confirm(
                            'Er is al een opdracht met dit project',
                            `Er is al een opdracht met project ${planningId.data}, wilt je een nieuwe opdracht maken of de bestaane openen?`,
                            'Nieuwe opdracht', 'Bestaande opdracht').then(() => {

                        }, () => {
                            this.form.markAsPristine();
                            this.getPlanning(planningId.data);
                            this.router.navigateByUrl(`vkm/${planningId.data}`, {replaceUrl: true});
                        });
                    }
                    projectIdSubs.unsubscribe();
                }));
            });
        }

        this.subscriptions.add(this.form.controls.contractor.valueChanges.subscribe(value => {
            value = value ? value : '';
            const sub = this.autocompleteService.getByType(AutocompleteType.contractors).subscribe(autoItems => {
                this.autocompleteContractors = autoItems.filter(a => a.toLowerCase().includes(('' + value).toLowerCase()));
            });
            sub.unsubscribe();
        }));
        this.countAndPrices();

    }

    save() {
        this.saving = true;
        Utils.triggerValidation(this.form);
        if (this.form.valid) {
            const planning = new Planning();
            Object.assign(planning, this.form.getRawValue());
            const currentFg = this.form.controls.planning_vkm.controls[this.currentTabIndex - (UserService.userHasRights(UserType.VKMEXECUTOR) ? 1 : 0)];
            this.mayReload = true;
            this.subscriptions.add(this.vkmService.save(planning).subscribe(result => {
                this.saving = false;
                this.form.markAsPristine();
                if (!this.form.value.id) {
                    let params = {backTo: this.backTo} as any;
                    if (currentFg.value.id) {
                        params = {...params, planningHas: currentFg.value.id};
                    }
                    this.router.navigate([`vkm/${result.data.id}`, params], {replaceUrl: true});
                }
            }, () => {
                this.saving = false;
            }));
        }
    }

    isInViewport(element) {
        const rect = element?.getBoundingClientRect();
        return rect ? rect.top <= (window.innerHeight || document.documentElement.clientHeight) && (rect.top > -100 || rect.bottom > -100) : true;
    }

    showInfoBar() {
        this.infoBar = !this.isInViewport(document.querySelector('.project-selection'));
    }
}

export interface FgPlanningHas {
    id: FormControl<number>,
    type: FormControl<'MWO' | 'DWO'>,
    drawing: FormControl<string>,
    vg_plan: FormControl<string>,
    comment: FormControl<string>,
    performer_id: FormControl<number>,
    mechanic_id: FormControl<number>,
    mechanics: FormControl<number[]>,
    date: FormGroup<{
        date: FormControl<Date>,
        begintime: FormControl<number>,
        endtime: FormControl<number>,
    }>,
    begindate: FormControl<Date>,
    enddate: FormControl<Date>,
    workorderObjects: FormArray<FormGroup<FgWorkorderLine>>,
    workorderMaterials: FormArray<FormGroup<FgWorkorderLine>>,
    updated_at: FormControl<Date>,
    _ground_pot: FormControl<number>,
    _coupling_clamps: FormControl<number>
}

export interface FgWorkorderLine {
    id: FormControl<number>,
    planning_has_id: FormControl<number>,
    description: FormControl<string>,
    entity_id: FormControl<number>,
    count_planned: FormControl<number>,
    count_placed: FormControl<number>,
    count_removed: FormControl<number>,
    count_lost: FormControl<number>,
    date_placed: FormControl<Date>,
    date_removed: FormControl<Date>,
    comment: FormControl<string>,
    checked_at: FormControl<Date>,
    checked_by: FormControl<number>,
    order: FormControl<number>,
    updated_at: FormControl<Date>
}
